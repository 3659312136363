/**
 *
 */

import React, { useState, useEffect } from "react";
// import DetailSection1 from "./DetailSection1";
// import DetailSection2 from "./DetailSection2";
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { EditGreen } from "../../../../../setup/constants/images";
import { useNavigate, useParams } from "react-router-dom";
import { customMoment } from "../../../../../setup/utils/global-helper";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
// import DetailSection1 from "./DetailSection1";
// import DetailSection2 from "./DetailSection2";
// import DetailSection3 from "./DetailSection3";
// import DetailSection4 from "./DetailSection4";
// import DetailSection5 from "./DetailSection5";
// import DetailSection8 from "./DetailSection8";
/* Import local pages and component ends */

/* Component starts */
const DetailCard = (props) => {
  /* Props destructuring starts */
  const { taskDetail, taskTab, user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { tab } = useParams();
  console.log(tab, "[PPPPR]", taskTab);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const startDate = taskDetail?.startdate || taskDetail?.routine_id?.startdate;
  const endDate = taskDetail?.enddate || taskDetail?.routine_id?.enddate;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status?.toUpperCase()}
      </span>
    );
  };

  const getDayFromTheDay = (date) => {
    let givenDate = new Date(date);
    let dayOfMonth = givenDate.getDate();

    return dayOfMonth;
  };

  function getDayOfWeek(day) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    return daysOfWeek[day]; // Get the day of the week as a string
  }
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card">
      <div className="main-wrapper">
        <h3 className="num fw_6 font_l font_grey">
          {taskTab ? "Task No." : "Ticket No."}{" "}
          <span className="font_blue">
            {taskTab ? taskDetail?.task_id : taskDetail?.ticket_id}
          </span>
        </h3>

        {taskDetail?.status === "Active" &&
          taskDetail?.requester?._id === user?._id && (
            <div className="d-flex justify-content-between gap-2">
              <div
                className="d-flex justify-content-between gap-2 pe-auto"
                onClick={() => navigate(`edit`)}
              >
                <IconButton className="action-button">
                  <img src={EditGreen} alt="Edit" className="h_100" />
                </IconButton>
                <span className="font_red fw_6 font_l">Edit</span>
              </div>
            </div>
          )}
      </div>

      <p className="summary font_m font_grey fw_4 my-2">
        Subject
        <br />
        <span className="fw_4 font_l">
          {taskTab ? taskDetail?.subject : taskDetail?.routine_id?.subject}
        </span>
      </p>

      <p className="summary font_m font_grey fw_5 my-2">
        Description
        <br />
        <span className="fw_6 font_l">
          {taskTab
            ? taskDetail?.description
            : taskDetail?.routine_id?.description}
        </span>
      </p>
      {tab === "task" && (
        <div className="date-wrapper">
          <div>
            <span className="font_m fw_3 font_grey">Start Date & Time</span>
            <br />
            <span className="font_m fw_3">
              {taskTab
                ? customMoment(
                    startDate,
                    DATE_TIME_FORMAT,
                    true,
                    taskDetail?.property_offset
                  )
                : customMoment(
                    startDate,
                    DATE_TIME_FORMAT,
                    true,
                    taskDetail?.property_offset
                  )}
            </span>
          </div>

          <div>
            <span className="font_m fw_3 font_grey">End Date & Time</span>
            <br />
            <span className="font_m fw_3">
              {taskTab
                ? customMoment(
                    endDate,
                    DATE_TIME_FORMAT,
                    true,
                    taskDetail?.property_offset
                  )
                : customMoment(
                    endDate,
                    DATE_TIME_FORMAT,
                    true,
                    taskDetail?.property_offset
                  )}
            </span>
          </div>

          <div>
            <span className="font_m fw_3 font_grey">Type</span>
            <br />
            <span className="font_m fw_3">
              {taskTab ? taskDetail?.type : taskDetail?.routine_id?.type}
            </span>
          </div>
        </div>
      )}
      <hr />
      <div className="location-wrapper">
        {tab === "task" && (
          <div>
            <span className="font_m fw_3 font_grey">Frequency</span>
            <br />{" "}
            <span className="font_m fw_6 font_blue">
              {taskTab
                ? taskDetail?.frequency
                : taskDetail?.routine_id?.frequency}
            </span>
          </div>
        )}

        {taskTab && tab === "task" && (
          <div>
            <span className="font_m fw_3 font_grey">Daily Time At</span>
            <br />{" "}
            <span className={`font_m fw_6 font_blue`}>
              {taskTab
                ? taskDetail?.daily_time
                : taskDetail?.routine_id?.daily_time}
            </span>
          </div>
        )}

        {!taskTab && tab === "task" && (
          <div>
            <span className="font_m fw_3 font_grey">Task Id</span>
            <br />{" "}
            <span className={`font_m fw_6 font_blue`}>
              {taskDetail?.routine_id?.task_id}
            </span>
          </div>
        )}

        {taskDetail?.weekly_days && tab === "task" && (
          <div>
            <span className="font_m fw_3 font_grey">Weekly At</span>
            <br />{" "}
            <span className={`font_m fw_6 font_blue`}>
              {getDayOfWeek(taskDetail?.weekly_days)}
            </span>
          </div>
        )}

        {taskDetail?.monthly_date && tab === "task" && (
          <div>
            <span className="font_m fw_3 font_grey">Monthly At</span>
            <br />{" "}
            <span className={`font_m fw_6 font_blue`}>
              {getDayFromTheDay(taskDetail?.monthly_date)}
            </span>
          </div>
        )}

        <div>
          <span className="font_m fw_3 font_grey">Status</span>
          <br />{" "}
          <span
            className={`font_m fw_5 ${
              taskDetail?.status === "Active"
                ? "font_green"
                : taskDetail?.status === "Open"
                ? "font_yellow"
                : "font_red"
            } `}
          >
            {taskDetail?.status}
          </span>
        </div>
      </div>
      <hr />
      {taskTab && taskDetail?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {taskDetail?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  videoThumbnail={image?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />
              </div>
            ))}
          </div>

          <hr />
        </>
      )}

      {!taskTab && taskDetail?.routine_id?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {taskDetail?.routine_id?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  videoThumbnail={image?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />
              </div>
            ))}
          </div>

          <hr />
        </>
      )}

      <div className="ticket-wrapper">
        <p className="font_m fw_4 font_grey">
          Ticket Requester{" "}
          <span className="font_m fw_3">
            {taskDetail?.assigned !== null
              ? `${taskDetail?.assigned?.first_name}${" "}
            ${taskDetail?.assigned?.last_name} `
              : "NA"}
          </span>
        </p>
      </div>
      <div className="last-update bg_black">
        <p className="font_m fw_3 font_white">
          Request Date{" "}
          <span className="font_s fw_4 font_white">
            {moment(taskDetail?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </p>
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailCard;
