/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchNotifications } from "../../setup/store/slices/notificationSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import NotificationList from "../../components/module/Notificationx/NotificationList/NotificationList";
/* Import local pages and component ends */

/* Component starts */
const NotificationPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [notifications, setNotifications] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [notifToDelete, setNotifToDelete] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const isTenant = localStorage.getItem("user_role") === "Tenant";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const fetchNotifs = async () => {
    try {
      const result = await dispatch(
        fetchNotifications(USER_DATA ? USER_DATA?._id : "")
      );
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setNotifications([]);
          break;
        case GLOBAL.FULFILLED:
          setNotifications(result.payload);

          break;
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  useEffect(() => {
    fetchNotifs();
  }, []);
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <NotificationList
        fetchNotifs={fetchNotifs}
        notifications={notifications}
        isTenant={isTenant}
      />
    </div>
  );
};
/* Component ends */

export default NotificationPage;
