/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fieldPropExtractor, initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../setup/store/slices/unpersistedSlice";
import {
  addSecurityIncident,
  fetchCompanyListBasedProperty,
  fetchTeamMemberBasedOnCompany,
  fetchTenantUnitsBasedPropertyId,
  getCompaniesList,
  getCompanyMembersList,
  getTenantsLists,
} from "../../../../../setup/store/slices/securitySlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../../setup/constants/global";

import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextArea from "../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomDateTimeInput from "../../../../ui/input/CustomDateTimeInput/CustomDateTimeInput";
import CustomPhoneInput from "../../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import SuccessModal from "../../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";

/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AddSCF = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [date, setDate] = useState(new Date());
  const [voilator, setVoilator] = useState("Tenants");
  const [selectedUnitNo, setSelectedUnitNo] = useState();
  const [filterCompanyList, setFilterCompanyList] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedMemberList, setSelectedMemberList] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Other hooks declaration ends */

  // Redux Selectors
  // const tenantsList1 = useSelector(getPropertyTenants);
  const { propertyId } = useParams();
  const tenantList = useSelector(getTenantsLists);
  const companyList = useSelector(getCompaniesList);
  const teamMembersList = useSelector(getCompanyMembersList);
  const property = useSelector(getPropertyDetail);

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  //Loading all Team Members For Selected Company Name
  const loadTeamMembers = async () => {
    if (selectedCompany != null) {
      setSelectedMemberList(null);

      let payload = {
        propertyId: property?._id,
        compId: selectedCompany?.company_id,
      };

      await dispatch(fetchTeamMemberBasedOnCompany(payload));
    }
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, voilator);
  };

  const onSubmit = async (values) => {
    console.log("V-ID", values);
    const payload = {
      subject: values.incident_sub,
      location: values.location,
      description: values.description,
      violator: voilator,
      date: date,
      document: [],
    };

    values.photos.forEach((i) => {
      if (i) {
        payload["document"]?.push(i);
      }
    });

    if (voilator === "Tenants") {
      payload["property_id"] = property?._id;
      payload["violator_id"] = selectedUnitNo?.tenant_id || null;
      payload["unit_id"] = selectedUnitNo?.unit_id || null;
    }

    if (voilator === "Teams") {
      payload["property_id"] = property?._id;
      payload["team_member_id"] = selectedMemberList?.mem_id || null;
    }

    if (voilator === "Others") {
      payload["property_id"] = property?._id;
      payload["violator_name"] = values.fullName;
      payload["email"] = values.email || "";
      payload["mobile_number"] = values.mobile || "";
      payload["company_name"] = values.company || "";
    }

    console.log(payload, "PAY");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addSecurityIncident(payload));

      console.log(result, "ADD_SCF");

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Security Incident Created Successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    dispatch(fetchTenantUnitsBasedPropertyId({ property_id: property?._id }));
    dispatch(fetchCompanyListBasedProperty(property?._id));
  }, [dispatch]);

  // Setting Tenant Unit after loading Tenant List
  useEffect(() => {
    if (tenantList?.length > 0) {
      setSelectedUnitNo(tenantList[0]);
    }
  }, [tenantList]);

  // Setting selected Company after loading Companies
  useEffect(() => {
    if (companyList?.length > 0) {
      setFilterCompanyList(
        companyList?.filter((item) => item.role !== "Owner")
      );
    }
  }, [companyList]);

  useEffect(() => {
    if (filterCompanyList?.length > 0) {
      setSelectedCompany(companyList);
    }
  }, [filterCompanyList]);

  // Loading All Team Members Based on selected Company
  useEffect(() => {
    loadTeamMembers();
  }, [selectedCompany]);

  // Setting selected Team Member after loading Companies List
  useEffect(() => {
    if (teamMembersList?.length > 0) {
      setSelectedMemberList(teamMembersList[0]);
    }
  }, [teamMembersList]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-scf-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Create Security Incident
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Security Incident */}
                <>
                  {/* Input for Incident Subject */}
                  <div className="form-group">
                    <CustomTextInput
                      name="incident_sub"
                      label="Incident Subject"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.incident_sub}
                      errors={errors.incident_sub}
                      value={values.incident_sub}
                    />
                  </div>

                  {/* Input for Location */}
                  <div className="form-group">
                    <CustomTextInput
                      name="location"
                      label="Location"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.location}
                      errors={errors.location}
                      value={values.location}
                    />
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Radio select the Voilator */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">Voilator</p>
                    <CustomRadioInput
                      label="Tenants"
                      onSelecting={() => {
                        setVoilator("Tenants");
                      }}
                      isSelected={voilator === "Tenants"}
                    />
                    {property?.mgmt_model === 2 && (
                      <CustomRadioInput
                        label="Teams"
                        onSelecting={() => {
                          setVoilator("Teams");
                        }}
                        isSelected={voilator === "Teams"}
                      />
                    )}
                    <CustomRadioInput
                      label="Others"
                      onSelecting={() => {
                        setVoilator("Others");
                      }}
                      isSelected={voilator === "Others"}
                    />
                  </div>

                  {/* If Selected Tenant */}
                  {voilator === "Tenants" && (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="unit_num"
                          label="Select Tenant Unit"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) =>
                            //item?.unit_number
                            `${item?.unit_name} - ${item?.tenant_name}`
                          }
                          setValue={setSelectedUnitNo}
                          options={tenantList}
                          value={selectedUnitNo}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>
                    </>
                  )}

                  {/* If Selected Team */}
                  {voilator === "Teams" && property?.mgmt_model === 2 && (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="companyName"
                          label="Select Company Name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) => item?.company_name}
                          setValue={setSelectedCompany}
                          options={filterCompanyList}
                          value={selectedCompany}
                          formikState={false}
                          emptyOption={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomSelectInput
                          name="teamMember"
                          label="Team Member Name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) =>
                            `${item?.mem_name} - ${item?.mem_role}`
                          }
                          setValue={setSelectedMemberList}
                          options={teamMembersList}
                          value={selectedMemberList}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>
                    </>
                  )}

                  {/* If Selected Others */}
                  {voilator === "Others" && (
                    <>
                      <div className="form-group">
                        <CustomTextInput
                          name="fullName"
                          label="Full Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.fullName}
                          errors={errors.fullName}
                          value={values.fullName}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="email"
                          label="Email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.email}
                          errors={errors.email}
                          value={values.email}
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <CustomPhoneInput
                          name="mobile"
                          label={"Mobile Number"}
                          customPhoneNum={customPhoneNum}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.mobile}
                          countryCodeEditable={true}
                          errors={errors.mobile}
                          touched={touched.mobile}
                          required={false}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="company"
                          label="Company"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.company}
                          errors={errors.company}
                          value={values.company}
                          required={false}
                        />
                      </div>
                    </>
                  )}

                  {/* Input for Date */}
                  <div className="form-group">
                    <CustomDateTimeInput
                      label="Date & Time"
                      value={date}
                      minDate={false}
                      customOnchange={(date) => setDate(date)}
                    />
                  </div>

                  {/* Input for ID Photo */}
                  <FieldArray
                    name="photos"
                    render={({ push, pop }) => (
                      <div className="form-group">
                        {/* Upload Image List */}
                        {values?.photos?.map((item, index) => (
                          <div
                            key={index}
                            className={index >= 1 ? "add-image" : ""}
                          >
                            <CustomFileInput
                              name={`photos[${index}]`}
                              label="Photo"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              value={values.photos[index]}
                              formikState={true}
                              touched={fieldPropExtractor(touched, index)}
                              validaterror={fieldPropExtractor(errors, index)}
                              required={false}
                            />
                          </div>
                        ))}

                        <div className="action-button text-center">
                          {values.photos[values.photos.length - 1] && (
                            <TextButton
                              variant="outline"
                              size="m"
                              className="txtBtn"
                              onClick={() => onAddPhoto(push)}
                            >
                              + Add More Images/Videos
                            </TextButton>
                          )}

                          {values.photos.length > 1 && (
                            <CustomButton
                              className="remove"
                              size="m"
                              onClick={() => onRemovePhoto(pop)}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component Ends */
export default AddSCF;
