/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { Link, useParams } from "react-router-dom";
import {
  disabledStatusSwitch,
  canResendInvitation,
  canCancelInvitation,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateMemberStatus } from "../../../../setup/store/slices/teamSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteGrey,
  DeleteRed,
  EyeFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import IconButton from "../../../ui/button/IconButton/IconButton";
import TeamDetailModal from "../../../ui/modal/TeamDetailModal/TeamDetailModal";
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const OwnerTable = (props) => {
  /* Props destructuring starts */
  const { owners, canInvite, setSelectedTeamMember, showWarning, isT2 } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detail, setDetail] = useState(null);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = owners?.length > 1 && true;
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On toggle activating or suspending
  const toggleActiveSuspend = (user, status) => {
    if (status) {
      changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
    } else if (isT2 && !status) {
      changeStatus(user, GLOBAL.USER_STATUS.DEACTIVATED);
    } else {
      setModalDesc(
        "Cannot deactivate. Need to have atleast one active Owner. To automatically deactivate, you need to activate a deactivated Owner."
      );
      setShowErrorModal(true);
    }
  };

  // On switching owner activate or deactivate status
  const changeStatus = async (owner, status) => {
    // Collecting or creating payload data to be sent
    const payload = {
      member_id: owner?.application_id,
      status: status,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateMemberStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };

  // On deleting selected owner
  const onDelete = (owner) => {
    changeStatus(owner, GLOBAL.USER_STATUS.DEACTIVATED);
  };

  // On viewing details of seleted owner
  const onView = (owner) => {
    setDetail(owner);
    setShowDetailsModal(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="owner-table-module-wrapper">
      <div className="main-heading">
        <h3 className="fw_6 font_xl">Owner</h3>
        {isClient && (
          <p
            onClick={() => {
              canInvite(GLOBAL.USER_ROLE.OWNER, "owner/add");
            }}
            // to="owner/add"
            className="fw_6 font_m font_blue"
          >
            + Add Owner
          </p>
        )}
      </div>

      <div className="custom-table-wrapper">
        {owners?.length !== undefined && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th></th>
                <th>Status</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {owners?.map((item, index) => (
                <tr key={index}>
                  {/* ID */}
                  <td>
                    <p className="col-different1">#{index + 1}</p>
                  </td>

                  {/* Name */}
                  <td>
                    <p className="col-different2">
                      {item?.first_name + " " + item?.last_name}
                    </p>
                  </td>

                  {/* Status */}
                  <td>
                    {(showSlider || item?.status === "expired") && (
                      <div className="col-different3">
                        <SliderButton
                          active={item?.status === GLOBAL.USER_STATUS.ACTIVE}
                          disabled={disabledStatusSwitch(item?.status)}
                          onChange={(currentState) =>
                            isClient && toggleActiveSuspend(item, currentState)
                          }
                        />
                      </div>
                    )}
                  </td>

                  {/* Invitaion Status */}
                  <td className="fw_6">
                    <p className="col-different4">
                      <StatusHighLight status={item?.status} />
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-start">
                    <IconButton
                      onClick={() => onView(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                    {/* //GLOBAL.USER_STATUS.SUSPENDED */}
                    {item?.status === "suspended" && (
                      <IconButton
                        onClick={() => onDelete(item)}
                        className="action-button"
                      >
                        <img src={DeleteRed} className="h_100" alt="Delete" />
                      </IconButton>
                    )}

                    {canResendInvitation(item.status) && (
                      <TextButton
                        onClick={() => {
                          setSelectedTeamMember(item);
                          showWarning("resend");
                        }}
                        className="font_m v_middle fw_6 action-txt-btn"
                      >
                        Resend
                      </TextButton>
                    )}
                    {canCancelInvitation(item.status) && (
                      <TextButton
                        onClick={() => {
                          setSelectedTeamMember(item);
                          showWarning("cancel");
                        }}
                        className="font_m v_middle fw_6 font_red action-txt-btn"
                      >
                        Cancel
                      </TextButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {/* If No PMC added yet */}
        {owners?.length === undefined && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No OWNER added yet
          </h3>
        )}
      </div>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={modalDesc}
        onClose={() => {
          setShowSuccessModal(false);
          setModalDesc("");
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => {
          setShowErrorModal(false);
          setModalDesc("");
        }}
      />

      {/* Team Detail Modal */}
      <TeamDetailModal
        showModal={showDetailsModal}
        detail={detail}
        onClose={() => setShowDetailsModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default OwnerTable;
