import GLOBAL from "../../../../setup/constants/global";

export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.current_status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    // Ticket Assigned
    case GLOBAL.MAINTENANCE_LOG_STATUS.ASSIGNED:
      title = `Ticket assigned to ${role}`;
      break;

    // Unit Readiness card created
    case "Unit Readiness card created":
      title = `Unit Readiness card created`;
      break;

    // Maintenance-Accepted
    case GLOBAL.MAINTENANCE_LOG_STATUS.MAINTENANCE_ACCEPTED:
      title = `Maintenance job completed by ${role}`;
      break;

    // Technician Assigned
    case "Technician Assigned":
      if (logType === "URIC Initial") {
        title = `Task assigned to technician. Waiting to get approved for UIRC Phase 1 Initial`;
      } else if (logType === "URIC Phase 2") {
        title = `Task assigned to technician`;
      } else if (logType === "Additional") {
        title = `Additional Task assigned to technician.`;
      } else {
        title = `Task assigned to technician. Waiting to get approved for UIRC Phase 1 Final`;
      }
      break;

    // Readiness Ticket false
    case "Readiness Ticket false":
      title = `Rejected UIRC request by ${role}`;
      break;

    // Readiness Ticket Accepted
    case "Readiness Ticket Accepted":
      if (logType === "URIC Initial") {
        title = `Approved URIC Phase 1 Initial request by ${role}`;
      } else {
        title = `Approved URIC Phase 1 Final request by ${role}`;
      }
      break;

    // MT submitted Readiness card Initial P-1
    // case "MT submitted Readiness card Initial P-1":
    case "Maintenance Technician submitted Readiness card Initial P-1":
      title = `${role} submitted URIC Phase 1 Intial form`;
      break;

    // FM pending initial p1 report review
    // case "FM pending initial p1 report review":
    case "Facility Manager pending initial p1 report review":
      title = `Review of URIC Phase 1 Intial by Facility Manager is pending`;
      break;

    // Readiness Ticket Rejected
    case "Readiness Ticket Rejected":
      if (logType === "URIC Initial") {
        title = `UIRC Phase 1 Initial report rejected by ${role}`;
      } else {
        title = `UIRC Phase 1 Final report rejected by ${role}`;
      }
      break;

    // Readiness Ticket Reassigned To FM
    // case "Readiness Ticket Reassigned To FM":
    case "Readiness Ticket Reassigned To Facility Manager":
      // title = `Readiness ticket reassigned to FM`;
      title = `Readiness Ticket Reassigned To Facility Manager`;
      break;

    // Readiness Ticket Reassigned
    case "Readiness Ticket Reassigned":
      if (currentStatus === "Reassigned To FM") {
        // title = `Readiness ticket reassigned to FM`;
        title = `Readiness Ticket Reassigned To Facility Manager`;
      } else {
        if (logType === "URIC Final") {
          title = `UIRC Phase 1 Final reassigned and assigned Technician to it`;
        } else {
          title = `UIRC Phase 1 Initial reassigned and assigned Technician to it`;
        }
      }
      break;

    // Readiness Ticket Approved
    case "Readiness Ticket Approved":
      if (logType === "URIC Initial") {
        title = `UIRC Phase 1 Initial approved by ${role}`;
      } else {
        title = `UIRC Phase 1 Final approved by ${role}`;
      }
      break;

    // UIRC final ticket created
    case "UIRC final ticket created":
      title = `UIRC Phase 1 Final created`;
      break;

    // MT submitted Readiness card Final P-1
    // case "MT submitted Readiness card Final P-1":
    case "Maintenance Technician submitted Readiness card Final P-1":
      title = `${role} submitted URIC Phase 1 Final form`;
      break;

    // FM pending final p1 report review
    // case "FM pending final p1 report review":
    case "Facility Manager pending final p1 report review":
      title = `Review of URIC Phase 1 Final by Facility Manager is pending`;
      break;

    //
    // case "FM skipped final card":
    case "Facility Manager skipped final card":
      title = `UIRC Phase 1 Final Assessment skipped by Facility Manager`;
      break;

    // UIRC initial assigned to PM
    // case "UIRC initial assigned to PM":
    case "UIRC initial assigned to Property Manager":
      title = `UIRC Phase 1 Initial sent to Property Manager for review`;
      break;

    // UIRC final assigned to PM
    // case "UIRC Final assigned to PM":
    case "UIRC Final assigned to Property Manager":
      title = `UIRC Phase 1 Final sent to Property Manager for review`;
      break;

    // UIRC phase 2 assigned to FM
    // case "UIRC phase 2 assigned to FM":
    case "UIRC Phase 2 assigned to Facility Manager":
      title = `UIRC Phase 2 created`;
      break;

    // Quoatation updated succesfully
    case "Quoatation updated succesfully":
      title = `Quoatation submitted succesfully`;
      break;

    // Quoatation submitted succesfully
    case "Quoatation submitted succesfully":
      title = `Quoatation submitted succesfully`;
      break;

    // Quoatation assigned To PM
    // case "Quoatation assigned To PM":
    case "Quoatation assigned To Property Manager":
      title = `Quotation assigned To Property Manager`;
      break;

    // Quoatation Approved by PM
    case "Quoatation Approved by PM":
      title = `Spare Parts/Service Approved. Assigned to Facility Manager`;
      break;

    // Quoatation Approved by PM
    // case "FM received approval":
    case "Facility Manager received approval":
      title = `Waiting for Facility Manager to place order`;
      break;

    // Order status updated by FM
    case "Order status updated by FM":
      if (currentStatus === "Order Placed") {
        title = `Order has been placed`;
      } else {
        title = `Spare parts has arrived`;
      }
      break;

    // Quoatation Rejected by PM
    case "Quoatation Rejected by PM":
      title = `Quoatation Rejected by PM`;
      break;

    // Quoatation reassigned to FM
    // case "Quoatation reassigned to FM":
    case "Quoatation reassigned to Facility Manager":
      // title = `Quoatation reassigned to FM`;
      title = `Quoatation reassigned to Facility Manager`;
      break;

    // Keys are ready to collect
    case "Keys are ready to collect":
      title = `Keys are ready to collect`;
      break;

    //
    case "Keys Rejected By Tenant":
      title = `Keys Rejected By Tenant`;
      break;

    //
    case "Owner submitted Readiness card Initial P-1":
      title = `Owner submitted Readiness card Initial P-1`;
      break;

    //
    case "Owner approved p1 report review":
      title = `Owner approved p1 report review`;
      break;

    //
    case "":
      title = ``;
      break;

    // Default Case...
    default:
      title = "";
  }

  return title;
};

export const statusColorGenerate = (status) => {
  switch (status) {
    case "New":
      return "font_blue";

    case "Closed":
      return "font_green";

    case "Rejected":
      return "font_red";

    default:
      return "";
  }
};

export const orderStatusGenerate = (status) => {
  if (status === "Approved") {
    return {
      status: "Order Placed",
      button: "Place Order",
    };
  }
  if (status === "Order Placed") {
    return {
      status: "Parts Recieved",
      button: "Recieve Parts",
    };
  }
};

export const taskStatusGenerate = (status) => {
  switch (status) {
    case "technician_acceptance_pending":
    case "Pending":
      return { color: "font_yellow", text: "Pending Technician approval" };

    case "accepted_by_technician":
      return { color: "font_green", text: "Technician Accepted" };

    case "rejected_by_technician":
      return { color: "font_red", text: "Technician Rejected" };

    case "Completed":
      return { color: "font_blue", text: "Task Completed" };

    case "pm_approval_pending":
      return { color: "font_yellow", text: "PM Approval Pending" };

    case "Approved":
      return { color: "font_green", text: "Approved" };

    case "rejected_by_pm":
      return { color: "font_red", text: "Rejected By PM" };

    default:
      return {};
  }
};

const userActionPermission = (item, user) => {
  const logRequester = item?.requester?._id;
  const logRequesterRole = item?.requester_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

// FM to assign technician
export const canAssignTechnician = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requestor = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    requestor !== "Owner" &&
    (action === "Ticket Assigned" ||
      action === "Unit Readiness card created" ||
      action === "Readiness Ticket false" ||
      // action === "Readiness Ticket Reassigned To FM" ||
      action === "Readiness Ticket Reassigned To Facility Manager" ||
      (action === "Readiness Ticket Reassigned" &&
        currentStatus === "Reassigned To FM") ||
      action === "UIRC final ticket created")
  ) {
    return true;
  }

  return false;
};

// MT to take action for UIRC Phase 1 Intial request
export const mtRespondUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Technician Assigned" ||
    (action === "Readiness Ticket Reassigned" &&
      currentStatus !== "Reassigned To FM")
  ) {
    return true;
  }

  return false;
};

export const ownerCanFillReport = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requestor = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (requestor === "Owner" && action === "Unit Readiness card created") {
    return true;
  }
};

// MT to fill URIC Phase 1
export const canFillURICP1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Readiness Ticket Accepted") {
    return true;
  }
};

// FM to approve URIC Phase 1 Initial form details
export const fmCanApproveURICP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "FM pending initial p1 report review" &&
    action === "Facility Manager pending initial p1 report review" &&
    logType === "URIC Initial"
  ) {
    return true;
  }
};

// Checking if all estimations have been put on UIRC P1
export const putAllEstimations = (items) => {
  return items.every((item, index) => item && !isNaN(item));
};

// FM to review URIC Phase 1 Initial form
export const canReviewUIRCP1Init = (items) => {
  return putAllEstimations(items);
};

// PM to approve UIRC Phase 1 Initial Card
export const pmCanApproveUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    //action === "UIRC initial assigned to PM"
    action === "UIRC initial assigned to Property Manager"
  ) {
    return true;
  }
};
