import React, { useState, useRef, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { fieldKeys, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const CreateQuotationInvoiceModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onSubmit,
    canSelectTenant = false,
    isT1 = false,
    tenantList = [],
    closeOnSubmit = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [fieldInititalization, setFieldInititalization] = useState(fieldKeys);
  const [costCoveredBy, setCostCoveredBy] = useState("Other");
  const [selectedTenant, setSelectedTenant] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const formRef = useRef();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const createInv = (v) => {
    const payload = {
      paid_by:
        costCoveredBy === GLOBAL.USER_ROLE.TENANT ? "tenant" : "management",
      payer_description: v?.description,
      payer_amount: v?.amount,
    };

    if (canSelectTenant && tenantList && selectedTenant) {
      payload["tenant_id"] = selectedTenant?.tenant_id;
      payload["property_id"] = selectedTenant?.propert_id;
      payload["unit_id"] = selectedTenant?.unit_id;
    }

    closeOnSubmit && onClose();
    onSubmit(payload);
  };

  //   const createInvHandler = () => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {}, [selectedTenant]);
  /* Component useEffect ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`create-invoice-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xxl fw_6">
            Quotation Invoice
          </h2>
        </div>
        <div className="modal-body-custom">
          <p className="description font_s font_grey">
            {/* {description} */}
            Please select who is going to cover the cost for the items or
            services mentioned in the quotation.
          </p>

          <div className="cost-covered-by-radio-button">
            <CustomRadioInput
              className="radio-group"
              labelClass="font_xs fw_5"
              label={isT1 ? "Owner" : "Management"}
              isSelected={costCoveredBy === "Other"}
              onSelecting={() => setCostCoveredBy("Other")}
            />
            {typeof canSelectTenant === "boolean" &&
              ((canSelectTenant && tenantList?.length > 0) ||
                !canSelectTenant) && (
                <CustomRadioInput
                  className="radio-group"
                  labelClass="font_xs fw_5"
                  label="Tenant"
                  isSelected={costCoveredBy === GLOBAL.USER_ROLE.TENANT}
                  onSelecting={() => {
                    setCostCoveredBy(GLOBAL.USER_ROLE.TENANT);
                  }}
                />
              )}
          </div>
          <div className="form_wrapper">
            <div>
              <Formik
                onSubmit={createInv}
                initialValues={fieldInititalization}
                validate={(values) =>
                  validations(
                    values,
                    canSelectTenant,
                    tenantList,
                    costCoveredBy
                  )
                }
                validateOnBlur={true}
                innerRef={formRef}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-fields">
                      {canSelectTenant &&
                        costCoveredBy === GLOBAL.USER_ROLE.TENANT && (
                          <div className="form-group">
                            <CustomSelectInput
                              label="Tenant"
                              name={`tenant`}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              options={tenantList}
                              value={values[`tenant`]}
                              errors={errors[`tenant`]}
                              touched={touched[`tenant`]}
                              valueExtractor={(d) => d?.tenant_name}
                              optionValueExtractor={(d) => d?.unit_id}
                              customOnchange={setSelectedTenant}
                            />
                          </div>
                        )}

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="amount"
                          label="Amount"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          touched={touched.amount}
                          errors={errors.amount}
                          value={values.amount}
                          currency="USD"
                          required={true}
                        />
                      </div>

                      {canSelectTenant && selectedTenant && (
                        <div className="tenant-detail-section">
                          <div className="row-custom">
                            <div className="left">
                              <span className="font_xs fw_5 font_grey">
                                Name :{" "}
                              </span>
                            </div>
                            <div className="right">
                              <span className="font_xs fw_5 font_black">
                                {selectedTenant?.tenant_name}
                              </span>
                            </div>
                          </div>
                          {/* <div className="row-custom">
                            <div className="left">
                              <span className="font_xs fw_5 font_grey">
                                Email :{" "}
                              </span>
                            </div>
                            <div className="right">
                              <span className="font_xs fw_5 font_black">
                                carter@gmail.com
                              </span>
                            </div>
                          </div>
                          <div className="row-custom">
                            <div className="left">
                              <span className="font_xs fw_5 font_grey">
                                Phone :{" "}
                              </span>
                            </div>
                            <div className="right">
                              <span className="font_xs fw_5 font_black">
                                +1 32145699
                              </span>
                            </div>
                          </div> */}
                          <div className="row-custom">
                            <div className="left">
                              <span className="font_xs fw_5 font_grey">
                                Unit Name :{" "}
                              </span>
                            </div>
                            <div className="right">
                              <span className="font_xs fw_5 font_black">
                                {selectedTenant?.unit_name}
                              </span>
                            </div>
                          </div>
                          <div className="row-custom">
                            <div className="left">
                              <span className="font_xs fw_5 font_grey">
                                Unit Number :{" "}
                              </span>
                            </div>
                            <div className="right">
                              <span className="font_xs fw_5 font_black">
                                {selectedTenant?.unit_number}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="description"
                          label="Description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.description}
                          errors={errors.description}
                          value={values.description}
                          required={true}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="modal-footer-custom">
          <CustomButton
            size="m"
            variant="primary"
            onClick={() => formRef.current?.submitForm()}
          >
            Create
          </CustomButton>

          <CustomButton size="m" variant="outline-red" onClick={onClose}>
            Close
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default CreateQuotationInvoiceModal;
