/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Modal } from "react-bootstrap";
/* Import configuration ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../button/CustomButton/CustomButton";
import IconButton from "../../button/IconButton/IconButton";
import {
  ExcalmatoryYellow,
  WarningRoundedOutlineYellow,
  GoToOutlineBlue,
  XRed,
  XRed_V2,
  XRed_V3,
  XRed_V4,
} from "../../../../setup/constants/images";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const NotificationItemDetailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "sm", item, onClose, handleNavigate } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const notificationText = item?.notification || "";
  const propertyName = item?.reference?.property_name || "";
  const unitName = item?.reference?.unit_name || "";
  // const invoiceId = "#4427RT";
  const maintenanceId = `#${item?.reference?.ticket_id || ""}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="notification-item-detail-modal-main-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="action-button">
            <div className="icon-btn-wrapper close-btn">
              <IconButton className="h_100" onClick={onClose}>
                <img className="h_100" src={XRed_V3} alt="Close" />
              </IconButton>
            </div>
          </div>
          <h2 className="text-center font_blue mt-3 font_m">{item?.title}</h2>
        </div>
        <div className="modal-body-custom">
          <div className="notification-txt font_xxs">
            <div className="icon-btn-wrapper icon-btn-wrapper-inline icon-btn-wrapper-sm go-to-btn">
              <IconButton
                className="h_100"
                onClick={() => handleNavigate(item)}
              >
                <img className="h_100" src={GoToOutlineBlue} alt="Go To" />
              </IconButton>
            </div>
            <span> {notificationText}</span>
          </div>

          <div className="other-details">
            {/* Property Name */}
            {propertyName && (
              <div className="detail-row">
                <p className="detail-col font_grey font_xxs fw_6 left">
                  Property Name
                </p>
                <p className="detail-col font_xxs fw_5 right">{propertyName}</p>
              </div>
            )}

            {/* Unit Name */}
            {unitName && (
              <div className="detail-row">
                <p className="detail-col font_grey font_xxs fw_6 left">
                  Unit Name
                </p>
                <p className="detail-col font_xxs fw_5 right">{unitName}</p>
              </div>
            )}

            {/* Maintenance ID */}
            {maintenanceId && (
              <div className="detail-row">
                <p className="detail-col font_grey font_xxs fw_6 left">
                  Maintenance ID
                </p>
                <p className="detail-col font_xxs fw_5 right">
                  {maintenanceId}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default NotificationItemDetailModal;
