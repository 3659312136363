/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */

import {
  fetchT2TenantInvoiceDetails,
  fetchTenantContractsDetails,
  fetchTenantDepositDetails,
  fetchTenantInvoiceDetails,
  fetchTenantMoveOutDetails,
  fetchTenantOnboardingDetails,
  fetchTenantOtherDetails,
  fetchTenantRentalDetails,
  fetchTenantSecurityDetails,
  fetchTenantViolationDetails,
  getContractsDetailList,
  getDepositDetailList,
  getInvoiceDetailList,
  getMoveOutDetailList,
  getOnboardingDetailList,
  getOthertDetailList,
  getRentalDetailList,
  getSecurityDetailList,
  getViolationDetailList,
} from "../../../../setup/store/slices/tenatDetailSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Contract from "./sub-component/Contract";
import Rent from "./sub-component/Rent";
import Deposits from "./sub-component/Deposits";
import Invoices from "./sub-component/Invoices";
import OtherDetails from "./sub-component/OtherDetails";
import TenantHistory from "./sub-component/TenantHistory";
import Facilities from "./sub-component/Facilities";
import Violations from "./sub-component/Violations";
import Maintenance from "./sub-component/Maintenance";
import Security from "./sub-component/Security";
import Notifications from "./sub-component/Notifications";
import Onboarding from "./sub-component/Onboarding";
import Requests from "./sub-component/Requests";
import UnitDetails from "./sub-component/UnitDetails";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import {
  fetchMaintenanceListByUnit,
  getMaintenanceList,
} from "../../../../setup/store/slices/maintenanceSlice";
import MoveOuts from "./sub-component/MoveOuts";

/* Component starts */
const TenantDetailTables = (props) => {
  /* Props destructuring starts */
  const { currentKey, unitInfo } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const contractList = useSelector(getContractsDetailList);
  const depositList = useSelector(getDepositDetailList);
  // const otherList = useSelector(getOthertDetailList);
  const rentalList = useSelector(getRentalDetailList);
  const invoiceList = useSelector(getInvoiceDetailList);
  const maintenanceList = useSelector(getMaintenanceList);

  const violationList = useSelector(getViolationDetailList);
  const securityList = useSelector(getSecurityDetailList);
  const moveOutList = useSelector(getMoveOutDetailList);
  const onboardingList = useSelector(getOnboardingDetailList);
  const property = useSelector(getPropertyDetail);

  const { tenantId, propertyId, unitId } = useParams();
  const [getParams] = useSearchParams();
  const { state } = useLocation();
  const unitID = unitId;

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const payload = {
    tenantId,
    unitId: unitID,
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        switch (currentKey) {
          case "contract":
            await dispatch(fetchTenantContractsDetails(payload));
            break;

          case "onboarding":
            await dispatch(fetchTenantOnboardingDetails(payload));
            break;

          case "deposits":
            await dispatch(fetchTenantDepositDetails(payload));
            break;

          case "rent":
            await dispatch(fetchTenantRentalDetails(payload));
            break;

          case "invoices":
            // dispatch(fetchTenantContractsDetails(payload));
            if (property?.mgmt_model === 1) {
              await dispatch(fetchT2TenantInvoiceDetails(payload));
            } else {
              await dispatch(fetchTenantInvoiceDetails(payload));
            }
            break;

          case "violations":
            await dispatch(fetchTenantViolationDetails(payload));
            break;

          case "security":
            await dispatch(fetchTenantSecurityDetails(payload));
            break;

          case "maintenance":
            await dispatch(
              fetchMaintenanceListByUnit({
                unit_id: unitID,
                tenant_id: tenantId,
              })
            );
            break;

          case "move-out":
            await dispatch(
              fetchTenantMoveOutDetails({
                unit_id: unitID,
                tenant_id: tenantId,
              })
            );
            break;

          default:
            break;
        }
      } catch (e) {}
    })();
  }, [currentKey, property]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-detail-table-module-wrapper">
      {currentKey === "contract" ? (
        <Contract contractList={contractList} property={property} />
      ) : currentKey === "rent" ? (
        <Rent rentalList={rentalList} property={property} />
      ) : currentKey === "deposits" ? (
        <Deposits depositList={depositList} property={property} />
      ) : currentKey === "invoices" ? (
        <Invoices
          invoiceList={invoiceList?.invoice_info}
          property={property}
          unitID={unitID}
        />
      ) : // : currentKey === "otherDetails" ? (
      //   <OtherDetails otherList={otherList} />
      // )
      currentKey === "tenantHistory" ? (
        <TenantHistory />
      ) : currentKey === "facilities" ? (
        <Facilities />
      ) : currentKey === "violations" ? (
        <Violations violationList={violationList} />
      ) : currentKey === "maintenance" ? (
        <Maintenance maintenanceList={maintenanceList?.maintenance} />
      ) : currentKey === "security" ? (
        <Security securityList={securityList} />
      ) : currentKey === "notifications" ? (
        <Notifications />
      ) : currentKey === "onboarding" ? (
        <Onboarding onboardingList={onboardingList} />
      ) : currentKey === "requests" ? (
        <Requests />
      ) : currentKey === "move-out" ? (
        <MoveOuts list={moveOutList} />
      ) : (
        <UnitDetails unitInfo={unitInfo} />
      )}
    </div>
  );
};
/* Component ends */

export default TenantDetailTables;
