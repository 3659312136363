/**
 *
 */

import React, { useState } from "react";
/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateRoutineTaskAsExpired } from "../../../../../setup/store/slices/routineSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteRed,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import { customMoment } from "../../../../../setup/utils/global-helper";

/* Component starts */
const ActiveTasks = (props) => {
  /* Props destructuring starts */
  const { taskLists, totalCount, currentPage, setCurrentPage, property } =
    props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isThirdPM = localStorage.getItem("user_role") === "Third Party Manager";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // function getDate(date) {
  //   let theDate = new Date(Date.parse(date));

  //   return (
  //     theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString("en-US")
  //   );
  // }

  // On delete Annoucement
  const markRoutineTaskAsExpired = (item) => {
    setTaskId(item);
    setModalDesc(
      "By ending the task all future recurrence will stop, are you sure you want to proceed?"
    );
    setShowConfirmationModal(true);
  };

  // On confirming delete
  const markConfirmedTaskAsExpired = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      routine_id: taskId?._id,
      property_id: taskId?.property_id?.id,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(updateRoutineTaskAsExpired(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setShowConfirmationModal(false);
          break;

        default:
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper pending-task-table-wrapper">
        {taskLists?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Start Date & Time</th>
                <th>End Date & Time</th>
                <th>Subject</th>
                <th>Type</th>
                <th>Frequency</th>
                <th>Assigned to</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {taskLists?.map((item, id) => (
                <tr key={item?._id}>
                  {/* ID */}

                  <td>{item?.task_id}</td>

                  {/* Start Date */}
                  {/* <td>{moment(item?.startdate).format(DATE_TIME_FORMAT)}</td> */}
                  <td>
                    {customMoment(
                      item?.startdate,
                      DATE_TIME_FORMAT,
                      true,
                      property?.offset
                    )}
                  </td>

                  {/* End Date */}
                  <td>
                    {/* //{moment(item?.enddate).format(DATE_TIME_FORMAT)} */}

                    {customMoment(
                      item?.enddate,
                      DATE_TIME_FORMAT,
                      true,
                      property?.offset
                    )}
                  </td>

                  {/* Subject */}
                  <td>{item?.subject}</td>

                  {/* Type */}
                  <td>{item?.type}</td>

                  {/* Frequency */}
                  <td>{item?.frequency}</td>

                  {/* Assigned To */}
                  <td>
                    {item?.assigned
                      ? `${item?.assigned?.first_name} ${item?.assigned?.last_name}`
                      : "-"}
                  </td>

                  {/* Status */}
                  <td className="font_blue">{item?.status}</td>

                  {/* Actions */}
                  <td>
                    <IconButton
                      onClick={() =>
                        navigate(item?._id, {
                          state: { taskTab: true },
                        })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {new Date(item?.enddate) >
                      new Date().setHours(0, 0, 0, 0) &&
                      item?.status === "Active" &&
                      (isFM || isThirdPM) && (
                        <IconButton
                          className="action-button"
                          onClick={() => markRoutineTaskAsExpired(item)}
                        >
                          <img
                            src={DeleteRed}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {taskLists?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {taskLists?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Ending This Task"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={markConfirmedTaskAsExpired}
      />
    </>
  );
};
/* Component Ends */
export default ActiveTasks;
