/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to handle team
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";

const initialState = {
  teamMemberList: [],
  missingMember: [],
  isTeamMissing: false,
};

export const getParentMember = createAsyncThunk(
  "property_team/user/member_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/property_team/user/" + payload.parent_user_id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addMember = createAsyncThunk(
  "property/members",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property_team/add_member",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const inviteMember = createAsyncThunk(
  // Done
  "property/member/invite",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (
          key == "profile_img" ||
          key == "resume" ||
          key == "contract_copy_img"
        ) {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: "image." + payload[key].type.split("/")[1],
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/property_team/invite_member", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property_team/invite_member",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchCanInviteTeam = createAsyncThunk(
  "property/can_invite_team",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL +
          `/property/${payload.property_id}/can_invite_team?role_name=${payload.role_name}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resendInvitationLink = createAsyncThunk(
  "property/resend_invitation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/property_team/resend_invitation/${payload.invitation_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const cancelInvitationLink = createAsyncThunk(
  "property/cancel_invitation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/property_team/cancel_invitation/${payload.invitation_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const inviteMemberWithAction = createAsyncThunk(
  // Done
  "property/member/invite",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property/members/invite",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTeamMembers = createAsyncThunk(
  "property/member/fetch",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      const { data } = await axios.get(
        API_URL + `/property_team/${property_id}/members`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const updateMemberStatus = createAsyncThunk(
  "property/member/status_update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL +
          `/property/${property_id}/members/${payload.member_id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateApprovalStatus = createAsyncThunk(
  "property/request_approval/status_update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL +
          `/property_team/update_approval_request/${payload.approval_id}/status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateContractCopy = createAsyncThunk(
  // Done
  "property/request_approval/contract_update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const { property_id } = payload;
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + `/property/request_approvals/${payload.request_approval_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTeamMembers({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    resetTeamList: (state, action) => {
      state.teamMemberList = [];
    },
  },
  extraReducers: {
    [fetchTeamMembers.fulfilled]: (state, action) => {
      state.teamMemberList = action.payload.team;
      state.missingMember = action.payload.missing_member_list;
      state.isTeamMissing = action.payload.is_missing;
    },
  },
});

export const getTeamMembers = (state) => state.team.teamMemberList;
export const getMissingMember = (state) => state.team.missingMember;
export const getIsTeamMissing = (state) => state.team.isTeamMissing;
export const { resetTeamList } = teamSlice.actions;

export default teamSlice.reducer;
