export const typeData = ["Spare Parts"];

export const costCoveredByData = ["Tenant", "Management", "FMC"];

export const costCoveredByDataT1 = ["Tenant", "Management"];

export const fieldValue = {
  // spare_part_request_number: "",
  // spare_part_quote_number: "",
  total_cost: "",
  invoice_amount: "", // ? Modification
  unit_id: "", // ? Modification
  invoice_description: "", // ? Modification
  notes: "",
  type_0: "",
  cost_covered_by_0: "",
  part_number_0: "",
  part_name_0: "",
  description_0: "",
  company_name_0: "",
  quantity_0: "",
  vendor_0: "",
  estimated_delivery_time_0: new Date(),
  warrenty_0: "",
  price_0: "",
};

// Validations
export const validation = (
  values,
  formCountIndex,
  setTotalPrice,
  costCoveredByTenant,
  canSelectTenant,
  tenantList_length,
  isTPM
) => {
  const error = {};
  let totalPrice = 0;

  if (!isTPM) {
    if (costCoveredByTenant) {
      /* Invoice Amount Validation Starts */
      if (values["invoice_amount"] === "") {
        error["invoice_amount"] = "Amount is required";
      }
      /* Invoice Amount Validation Ends */

      if (
        typeof canSelectTenant === "boolean" &&
        canSelectTenant &&
        tenantList_length > 0
      ) {
        /* Tenant Validation Starts */
        if (values["unit_id"] === "") {
          error["unit_id"] = "Please select Tenant";
        }
        /* Tenant Validation Ends */
      }
    }

    /* Invoice Description Validation Starts */
    if (
      values["invoice_description"] === "" &&
      (!canSelectTenant || tenantList_length > 0)
    ) {
      error["invoice_description"] = "Description is required";
    }
    /* Invoice Description Validation Ends */
  }

  formCountIndex.forEach((itm, idx) => {
    // ********************** Generating Object Keys **********************
    const type = "type_" + itm;
    const covered_by = "cost_covered_by_" + itm;
    const part_number = "part_number_" + itm;
    const part_name = "part_name_" + itm;
    const description = "description_" + itm;
    const company_name = "company_name_" + itm;
    const quantity = "quantity_" + itm;
    const vendor = "vendor_" + itm;
    const delivery_date = "estimated_delivery_time_" + itm;
    const warranty = "warrenty_" + itm;
    const price = "price_" + itm;
    const service_name = "service_name_" + itm;
    const service_amount = "service_amount_" + itm;

    // Doing other functions
    let currentPrice;

    // Getting total price
    if (values[type] === "Part Quote") {
      currentPrice = values[price] || 0;
    } else if (values[type] === "Service Quote") {
      currentPrice = values[service_amount] || 0;
    }

    totalPrice += parseInt(currentPrice);

    // ********************** Validations starts from here **********************
    /* Type Validation Starts */
    if (values[type] === "") {
      error[type] = "Type is required";
    }
    /* Type Validation Ends */

    if (values[type] === "Part Quote") {
      /* Cost Covered By Validation Starts */
      // if (values[covered_by] === "") {
      //   error[covered_by] = "Cost Covered By is required";
      // }
      /* Cost Covered By Validation Ends */

      /* Estimated Delivery Time Validation Starts */
      if (values[delivery_date] === null) {
        error[delivery_date] = "Estimated Delivery Time is required";
      }
      /* Estimated Delivery Time Validation Ends */

      /* Part Number Validation Starts */
      if (values[part_number] === "") {
        error[part_number] = "Part Number is required";
      }
      /* Part Number Validation Ends */

      /* Part Name Validation Starts */
      if (values[part_name] === "") {
        error[part_name] = "Part Name is required";
      }
      /* Part Name Validation Ends */

      /* Description Validation Starts */
      if (values[description] === "") {
        error[description] = "Description is required";
      }

      if (values[description] !== "" && values[description]?.length < 10) {
        error[description] = "Minimum 10 characters are required";
      }
      /* Description Validation Ends */

      /* Company Name Validation Starts */
      if (values[company_name] === "") {
        error[company_name] = "Company Name is required";
      }
      /* Company Name Validation Ends */

      /* Quantity Validation Starts */
      if (values[quantity] === "") {
        error[quantity] = "Quantity is required";
      }
      /* Quantity Validation Ends */

      /* Vendors Validation Starts */
      if (values[vendor] === "") {
        error[vendor] = "Vendors is required";
      }
      /* Vendors Validation Ends */

      /* Warranty Validation Starts */
      if (values[warranty] === "") {
        error[warranty] = "Warranty is required";
      }
      /* Warranty Validation Ends */

      /* Price Validation Starts */
      if (values[price] === "") {
        error[price] = "Price is required";
      }

      setTotalPrice(totalPrice);
      /* Price Validation Ends */
    } else if (values[type] === "Service Quote") {
      if (values[service_name] === "") {
        error[service_name] = "Service Name is required";
      }
      if (values[service_amount] === "") {
        error[service_amount] = "Service Amount is required";
      }

      setTotalPrice(totalPrice);
    }
  });
  return error;
};

// Adding Document field
export const addPartDetail = (
  formRef,
  setFormValue,
  partDetails,
  setPartDetails
) => {
  const lastIndx =
    partDetails.length === 0 ? 0 : partDetails[partDetails.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`type_${lastIndx}`] = "";
    currentFormState[`cost_covered_by_${lastIndx}`] = "";
    currentFormState[`part_number_${lastIndx}`] = "";
    currentFormState[`part_name_${lastIndx}`] = "";
    currentFormState[`description_${lastIndx}`] = "";
    currentFormState[`company_name_${lastIndx}`] = "";
    currentFormState[`quantity_${lastIndx}`] = "";
    currentFormState[`vendor_${lastIndx}`] = "";
    currentFormState[`estimated_delivery_time_${lastIndx}`] = new Date();
    currentFormState[`warrenty_${lastIndx}`] = "";
    currentFormState[`price_${lastIndx}`] = "";
    currentFormState[`service_name_${lastIndx}`] = "";
    currentFormState[`service_amount_${lastIndx}`] = "";
    return currentFormState;
  });
  setPartDetails((p) => [...p, lastIndx]);
};

// Remove Document field
export const removePartDetail = (
  formRef,
  setFormValue,
  partDetails,
  setPartDetails,
  index,
  setTotalPrice
) => {
  const removeVal = partDetails[index];
  const currentFormState = { ...formRef.current.values };

  setTotalPrice((prev) => {
    let removedPrice;
    if (currentFormState[`type_${removeVal}`] === "Part Quote") {
      removedPrice = parseInt(currentFormState[`price_${removeVal}`]);
    } else if (currentFormState[`type_${removeVal}`] === "Service Quote") {
      removedPrice = parseInt(currentFormState[`service_amount_${removeVal}`]);
    }
    return parseInt(prev) - removedPrice;
  });

  setFormValue(() => {
    delete currentFormState[`type_${removeVal}`];
    delete currentFormState[`cost_covered_by_${removeVal}`];
    delete currentFormState[`part_number_${removeVal}`];
    delete currentFormState[`part_name_${removeVal}`];
    delete currentFormState[`description_${removeVal}`];
    delete currentFormState[`company_name_${removeVal}`];
    delete currentFormState[`quantity_${removeVal}`];
    delete currentFormState[`vendor_${removeVal}`];
    delete currentFormState[`estimated_delivery_time_${removeVal}`];
    delete currentFormState[`warrenty_${removeVal}`];
    delete currentFormState[`price_${removeVal}`];
    delete currentFormState[`service_name_${removeVal}`];
    delete currentFormState[`service_amount_${removeVal}`];
    return currentFormState;
  });

  setPartDetails((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

// Form prefill when reviewed by FM
export const prefill = (data, setFormValue, setPartDetails, setTotalPrice) => {
  const prefillContainer = {};
  const formCount = [];

  prefillContainer[`spare_part_quote_number`] = data.spare_part_quote_number;
  prefillContainer[`spare_part_request_number`] =
    data.spare_part_request_number;
  prefillContainer[`total_cost`] = data.total_cost;
  prefillContainer[`notes`] = data.notes;

  data?.part?.forEach((item, index) => {
    prefillContainer[`company_name_${index}`] = item.company_name;
    prefillContainer[`cost_covered_by_${index}`] = item.cost_covered_by;
    prefillContainer[`description_${index}`] = item.description;
    prefillContainer[`estimated_delivery_time_${index}`] = new Date(
      item.estimated_delivery_time
    );
    prefillContainer[`part_name_${index}`] = item.part_name;
    prefillContainer[`part_number_${index}`] = item.part_number;
    prefillContainer[`price_${index}`] = item.price;
    prefillContainer[`quantity_${index}`] = item.quantity;
    prefillContainer[`type_${index}`] = item.type;
    prefillContainer[`vendor_${index}`] = item.vendor;
    prefillContainer[`warrenty_${index}`] = item.warrenty;
    prefillContainer[`service_name_${index}`] = item?.service_name || "";
    prefillContainer[`service_amount_${index}`] = item?.service_amount || "";
    formCount.push(index);
  });

  setTotalPrice(data?.total_cost);
  setFormValue(prefillContainer);
  setPartDetails(formCount);
};
