/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsTPM,
  getIsTPT,
} from "../../../../../setup/store/slices/authSlice";
import { getLoggedinUserRole } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SimpleLog from "./SimpleLog";
import QuotationLog from "./QuotationLog";
import SparePartLog from "./SparePartLog";
import HandoverItemLog from "./HandoverItemLog";
import InspectionReportLog from "./InspectionReportLog";
import MaintenanceFinalReportLog from "./MaintenanceFinalReportLog";
/* Import local pages and component ends */

/* Component starts */
const TimeLineLists = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    maintenance,
    logs,
    mgmtModel,
    isT1,
    isT2,
    isT3,
    status,
    property,
    user,
    managers,
    technicians,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const isTPM = useSelector(getIsTPM);
  // const isTPT = useSelector(getIsTPT);
  const isTPM = localStorage.getItem("user_role") === "Third Party Manager";
  const isTPT = localStorage.getItem("user_role") === "Third Party Technician";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const generateLogType = (item) => {
    const action = item?.action;
    const currentStatus = item?.current_status;
    const logType = item?.log_type;
    const requesterRole = item?.requester_role?.name;

    // Quotaion
    if (
      // action === "Quoatation assigned To PM" ||
      action === "Quoatation assigned To Property Manager" ||
      action === "Quoatation approved To Owner" ||
      action === "Quoatation Approved By Facility Manager" ||
      action === "Quoatation submitted succesfully"
    ) {
      return "quotation";
    }

    // Task
    if (
      action === "Technician Assigned" &&
      (logType === "URIC Phase 2" || logType.toLowerCase() === "additional")
    ) {
      return "task";
    }

    // Spare part
    if (action === "Spare parts request assigned To Manager") {
      return "spare_part";
    }

    // Handover Item
    if (action === "Maintenance Technician marks items to handover") {
      return "handover_item_part";
    }

    if (currentStatus === "inspection_report_added") {
      return "inspection_report";
    }

    if (
      currentStatus === "tenant_approval_pending" ||
      currentStatus === "waiting_for_tenant_approval" ||
      currentStatus === "rejected_by_tenant"
    ) {
      return "final_report";
    }
    return "simple";
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((item, index) => (
        <div className="log-wrapper" key={index}>
          {/* Simple Log */}
          {generateLogType(item) === "simple" && (
            <SimpleLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
            />
          )}

          {/* Inspection Report Log */}
          {generateLogType(item) === "inspection_report" && (
            <InspectionReportLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
            />
          )}

          {/* Maintenance Final Report Log */}
          {generateLogType(item) === "final_report" && (
            <MaintenanceFinalReportLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              managers={managers}
              technicians={technicians}
              isTPM={isTPM}
              isTPT={isTPT}
            />
          )}

          {/* Quotation Log */}
          {generateLogType(item) === "quotation" && (
            <QuotationLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}

          {/* Spare Part Log */}
          {generateLogType(item) === "spare_part" && (
            <SparePartLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              isTPM={isTPM}
              isTPT={isTPT}
            />
          )}

          {/* Handover Item Log */}
          {generateLogType(item) === "handover_item_part" && (
            <HandoverItemLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineLists;
