/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
// import {
//   fetchUserRoles,
//   getUserRoles,
// } from "../../../../../../setup/store/slices/masterSlice";
// import {
//   fetchSecurityOfficerBasedOnProperty,
//   getSecurityOfficersList,
// } from "../../../../../../setup/store/slices/securitySlice";
// import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../../setup/store/slices/masterSlice";
import { getSecurityOfficersList } from "../../../../../setup/store/slices/securitySlice";
import { setLoading } from "../../../../../setup/store/slices/unpersistedSlice";
import SimpleLog from "./SimpleLog";
// import OwnerLog from "./OwnerLog";
// import TenantLog from "./TenantLog";
// import SOLog from "./SOLog";
// import SMLog from "./SMLog";
// import PMLog from "./PMLog";
/* Import local pages and component ends */

/* Component starts */
const TimeLineListings = (props) => {
  /* Props destructuring starts */
  const { task, logs, property, user, isT1 } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const securityOfficersList = useSelector(getSecurityOfficersList);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const generateLogType = (item) => {
    const action = item?.action;
    const currentStatus = item?.current_status;
    const logType = item?.log_type;
    const requesterRole = item?.requester_role?.name;

    return "simple";
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [dispatch]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((item, index) => (
        <div className="log-wrapper" key={index}>
          {/* Simple Log */}
          {generateLogType(item) === "simple" && (
            <SimpleLog
              task={task}
              item={item}
              takeAction={index === 0}
              user={user}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineListings;
