/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchComplaintBasedOnId,
  getSecurityComplaintData,
} from "../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SecurityComplaintReport from "../../components/module/SecurityManage/ClientSecurityManage/SecurityComplaintReport/SecurityComplaintReport";

/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import SecurityComplaintDetail from "../../components/module/SecurityManage/ClientSecurityManage/TenantSecurityComplaintDetail/SecurityComplaintDetail";
import RoutineTaskDetail from "../../components/module/RoutineRequest/RoutineTaskDetail/RoutineTaskDetail";
import {
  fetchRoutineTaskOnId,
  fetchRoutineTaskTicketBasedOnId,
  getRoutineTaskData,
  getRoutineTaskTicketData,
} from "../../setup/store/slices/routineSlice";

/* Component starts */
const RoutineTaskDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  const { id, propertyId, taskId } = useParams();
  const dispatch = useDispatch();
  const taskDetail = useSelector(getRoutineTaskData);

  const taskTicketDetail = useSelector(getRoutineTaskTicketData);

  console.log(taskDetail, "[000]", taskTicketDetail);

  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  // console.log(user, "USER-MAIN");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // const type = complaint?.type;
  // const mgmtModel = complaint?.level;
  // const isT1 = mgmtModel === "t1";
  // const isT2 = mgmtModel === "t2";
  // const isT3 = mgmtModel === "t3";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (taskId && state?.taskTab) {
  //     dispatch(fetchRoutineTaskOnId({ id: taskId }));
  //   }
  // }, [taskId, state]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        if (taskId && state?.taskTab) {
          await dispatch(fetchRoutineTaskOnId({ routine_id: taskId }));
        } else {
          await dispatch(
            fetchRoutineTaskTicketBasedOnId({ routine_ticket_id: taskId })
          );
        }
        // await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [taskId, state]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="routine_task_details" />
      <div className="routine_task-page-wrapper">
        <RoutineTaskDetail
          taskDetail={state?.taskTab ? taskDetail : taskTicketDetail}
          taskTab={state?.taskTab}
          // complaint_id={id}
          property={property}
          user={user}
        />
      </div>
    </>
  );
};
/* Component ends */

export default RoutineTaskDetailPage;
