/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { logTitle } from "../helper";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import {
  fmCanApproveURICP1Init,
  canReviewUIRCP1Init,
  fmCanApproveURICP1Finl,
  canReviewUIRCP1Finl,
  pmCanApproveUIRCP1Init,
  pmCanApproveUIRCP1Finl,
  ownerCompletesTask,
  ownerAddQuote,
} from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  FMrespondReadinessCard,
  fmUpdateInitialCard,
  fmUpdateFinalCard,
  fmRespondFinalCard,
  pmRespondFinalCard,
  pmRespondInitialCard,
  t1markJobDone,
} from "../../../../../setup/store/slices/moveInSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import {
  EditGreen,
  EditWhite,
  XWhite,
  XGreen,
} from "../../../../../setup/constants/images";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import AssignTechnicianMoveInModal from "../../AssignTechnicianMoveInModal/AssignTechnicianMoveInModal";
import NoteModal from "../../NoteModal/NoteModal";
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import RejectedReportModal from "../../RejectedReportModal/RejectedReportModal";
import SparePartQuoteModal from "../../SparePartQuoteModal/SparePartQuoteModal";
/* Import local pages and component ends */

/* Component starts */
const URICP1Log = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showUIRCP1ReviewModal, setShowUIRCP1ReviewModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedValue, setEditedValue] = useState(null);
  const [estimates, setEstimates] = useState([]);
  const [isApproved, setIsApproved] = useState(null);
  const [openNote, setOpenNote] = useState(false);
  const [openPreviousReportModal, setOpenPreviousReportModal] = useState(false);
  const [addQuotation, setAddQuotation] = useState(false);

  const [totalEstimation, setTotalEstimation] = useState(0);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  // const name = "sdad";
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const initialURIC = maintenance?.initial_URIC;
  const finalUIRC = maintenance?.final_URIC;
  const maintenanceType = maintenance?.type;
  const uircP1InitItems = initialURIC?.items;
  const uircP1FinlItems = finalUIRC?.items;
  const unitCard = maintenance?.unit_card;
  const assignedToPM = currentStatus === "Ticket Assigned to PM";
  const p2URIC = maintenance?.phase2_URIC;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On triggering maintenance job done for tenant Move In by Owner in T1 Property
  const moveInMaintenanceDone = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id: maintenance._id,
      phase2_card: p2URIC?._id,
      status: "Approved",
      type: maintenance.type,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(t1markJobDone(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On performing respond action with note such as Reject and Approve
  const respondAction = (value) => {
    // PM approving URIC Phase 1 Initial
    pmCanApproveUIRCP1Init(item, user) && pmApproveUIRCP1Init(value);

    // PM approving URIC Phase 1 Final
    pmCanApproveUIRCP1Finl(item, user) && pmApproveUIRCP1Finl(value);
  };

  // On approving or rejecting UIRC Phase 1 Initial by PM
  const pmApproveUIRCP1Init = async (note) => {
    setIsApproved(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Approved" : "Rejected",
      readiness_card: initialURIC._id,
      type: maintenanceType,
      note,
      property_id: propertyId,
      log_id: item._id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondInitialCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On approving or rejecting UIRC Phase 1 Initial by PM
  const pmApproveUIRCP1Finl = async (note) => {
    setIsApproved(null);
    setOpenNote(false);

    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      status: isApproved ? "Approved" : "Rejected",
      readiness_card: finalUIRC._id,
      type: maintenanceType,
      note,
      property_id: propertyId,
      log_id: item._id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondFinalCard(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Approving or Rejecting UIRC Phase 1 Initial or Final Report. On rejecting adding technician
  const reviewReport = async (status, technician, note) => {
    setShowRejectionModal(false);
    setShowNoteModal(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      technician,
      status,
      note,
      type: maintenanceType,
      log_id: item?._id,
      property_id: propertyId,
    };
    if (logType === "URIC Initial") {
      payload["readiness_card"] = initialURIC?._id;
      const items = [];
      uircP1InitItems.forEach((item, index) => {
        items.push({
          item_id: item?._id,
          estimate: estimates[index],
        });
      });
      payload["items"] = JSON.stringify(items);
    } else if (logType === "URIC Final") {
      const items = [];
      payload["readiness_card"] = finalUIRC?._id;
      uircP1FinlItems.forEach((item, index) => {
        items.push({
          item_id: item?._id,
          estimate: estimates[index],
        });
      });
      payload["items"] = JSON.stringify(items);
    }

    // Backend response. Try, Catch
    try {
      let result = {};
      if (logType === "URIC Initial") {
        result = await dispatch(FMrespondReadinessCard(payload));
      } else if (logType === "URIC Final") {
        result = await dispatch(fmRespondFinalCard(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On editing current estimate field
  const onEdit = (index, value) => {
    setEditedIndex(index);
    setEditedValue(value || "0");
  };

  // On cancelling current estimate field
  const onCancel = () => {
    setEditedIndex(null);
    setEditedValue("0");
  };

  // On saving current estimated value
  const onSaveEstimate = async () => {
    onCancel();
    // Creating or collecting payload data to be sent
    setEstimates((prev) => {
      const t = [...prev];
      t[editedIndex] = editedValue;
      return t;
    });
  };

  const calculateTotalEstimation = (items) => {
    const total = items?.reduce((acc, item) => acc + item?.estimate, 0);
    setTotalEstimation(total);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (logType === "URIC Initial") {
      if (uircP1InitItems && uircP1InitItems.length > 0) {
        const t = [];
        uircP1InitItems.forEach(() => {
          t.push(null);
        });
        setEstimates(t);
      }
    } else {
      if (uircP1FinlItems && uircP1FinlItems.length > 0) {
        const t = [];
        uircP1FinlItems.forEach(() => {
          t.push(null);
        });
        setEstimates(t);
      }
    }
  }, [initialURIC, uircP1InitItems]);

  useEffect(() => {
    calculateTotalEstimation(item?.data?.items);
  }, [item]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line uric-p1-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        <div className="uirc-detail">
          {/* Details */}
          <div className="black-container-sm uirc-data">
            {item?.data?.items?.map((estimateItem, index) => (
              <div className="report-item">
                {/* Header */}
                <h2 className="font_m fw_6 font_blue report-item-heading">
                  Report Item #{index + 1}
                </h2>
                <div className="r font_s fw_5">
                  <p className="c1 font_grey">Description</p>
                  <p className="c2 font_grey">Estimate ({property.currency})</p>
                </div>

                {/* Body */}
                <div key={index} className="r font_s fw_5">
                  <div className="c1">
                    <p className="font_m fw_5 font_white">
                      {estimateItem?.description}
                    </p>

                    <h3 className="attachments-txt fw_5 font_s font_grey">
                      Attachments
                    </h3>
                    {estimateItem?.documents?.length > 0 ? (
                      <div className="doc-row">
                        {estimateItem?.documents?.map((doc, index) => (
                          <Image
                            onClick={() => {
                              setFile(doc);
                              setShowFile(true);
                            }}
                            src={doc?.url}
                            videoThumbnail={doc?.type2 === "video"}
                            className="doc-item"
                            objectFit="cover"
                          />
                        ))}
                      </div>
                    ) : (
                      <p className="font_xxs font_yellow fw_4">
                        No Attachments Added!
                      </p>
                    )}
                    {/* <div className="doc-row">
                      {estimateItem?.documents?.map((doc, index) => (
                        <Image
                          onClick={() => {
                            setFile(doc);
                            setShowFile(true);
                          }}
                          src={doc?.url}
                          videoThumbnail={doc?.type === "mp4"}
                          className="doc-item"
                          objectFit="cover"
                        />
                      ))}
                    </div> */}
                  </div>

                  {!assignedToPM && !isT1 && (
                    <div className="c2">
                      <div className="edit-section">
                        {editedIndex === index ? (
                          // Edited Value
                          <CustomCurrencyInput
                            variant="2"
                            setValue={setEditedValue}
                            value={editedValue}
                            currency={property?.currency}
                            inputClass="fw_5"
                          />
                        ) : (
                          // Original Value
                          <>
                            {takeAction ? (
                              <input
                                type="text"
                                className="transparent-input fw_5"
                                readOnly={true}
                                value={
                                  estimates[index]?.toLocaleString("en-US") ||
                                  "-"
                                }
                              />
                            ) : (
                              <input
                                type="text"
                                className="transparent-input fw_5"
                                readOnly={true}
                                value={estimateItem?.estimate?.toLocaleString(
                                  "en-US"
                                )}
                              />
                            )}
                          </>
                        )}
                        {/* Action buttons */}
                        {takeAction && (
                          <>
                            {(fmCanApproveURICP1Init(item, user) ||
                              fmCanApproveURICP1Finl(item, user)) && (
                              <>
                                {editedIndex === index ? (
                                  // Cancel
                                  <IconButton onClick={() => onCancel(index)}>
                                    <img
                                      // src={XWhite}
                                      src={XGreen}
                                      alt="Delete"
                                      className="icon-sm"
                                    />
                                  </IconButton>
                                ) : (
                                  // Edit
                                  <IconButton
                                    onClick={() =>
                                      onEdit(index, estimates[index])
                                    }
                                  >
                                    <img
                                      // src={EditWhite}
                                      src={EditGreen}
                                      alt="Edit"
                                      className="icon-sm"
                                    />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* Save Estimate */}
                      {editedIndex === index && (
                        <div className="sm-btn-wrapper">
                          <CustomButton
                            onClick={onSaveEstimate}
                            size="s"
                            variant="outline"
                          >
                            Save
                          </CustomButton>
                        </div>
                      )}
                    </div>
                  )}

                  {(assignedToPM || isT1) && (
                    <div className="c2">
                      <input
                        type="text"
                        className="transparent-input fw_5"
                        readOnly={true}
                        value={estimateItem?.estimate?.toLocaleString("en-US")}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}

            <hr />

            <div className="my-2 r font_s fw_5">
              <p className="font_m fw_5 c1 font_grey">Total Amount </p>
              <p className="font_m fw_6 c2 font_blue">
                {totalEstimation?.toLocaleString("en-US")} ({property?.currency}
                )
              </p>
            </div>
          </div>

          {/* Footer */}
          <div className="log-footer">
            {/* Action Button */}
            {takeAction && (
              <div className="button-wrapper">
                {/* Owner add quote */}
                {isT1 && ownerAddQuote(item, user) && (
                  <CustomButton
                    onClick={() => setAddQuotation(true)}
                    variant="yellow"
                    size="m"
                  >
                    Generate Quote
                  </CustomButton>
                )}

                {/* Owner completes task */}
                {isT1 && ownerCompletesTask(item, user) && (
                  <CustomButton
                    onClick={() => moveInMaintenanceDone()}
                    variant="yellow"
                    size="m"
                  >
                    Complete Task
                  </CustomButton>
                )}

                {/* FM Approving or Rejecting UIRC Phase 1 Initial Report */}
                {fmCanApproveURICP1Init(item, user) && (
                  <>
                    {canReviewUIRCP1Init(estimates) && (
                      <CustomButton
                        onClick={() => setShowNoteModal(true)}
                        size="m"
                        variant="yellow"
                      >
                        Approve
                      </CustomButton>
                    )}
                    <CustomButton
                      onClick={() => setShowRejectionModal(true)}
                      variant="red"
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

                {/* FM Approving or Rejecting UIRC Phase 1 Final Report */}
                {fmCanApproveURICP1Finl(item, user) && (
                  <>
                    {canReviewUIRCP1Finl(estimates) && (
                      <CustomButton
                        onClick={() => setShowNoteModal(true)}
                        size="m"
                        variant="yellow"
                      >
                        Approve
                      </CustomButton>
                    )}
                    <CustomButton
                      onClick={() => setShowRejectionModal(true)}
                      variant="red"
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

                {/* PM Approving URIC Phase 1 Initial */}
                {pmCanApproveUIRCP1Init(item, user) && (
                  <>
                    <CustomButton
                      onClick={() => {
                        setIsApproved(true);
                        setOpenNote(true);
                      }}
                      size="m"
                      variant="yellow"
                    >
                      Approve
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        setIsApproved(false);
                        setOpenNote(true);
                      }}
                      variant="red"
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}

                {/* PM Approving URIC Phase 1 Final */}
                {pmCanApproveUIRCP1Finl(item, user) && (
                  <>
                    <CustomButton
                      onClick={() => {
                        setIsApproved(true);
                        setOpenNote(true);
                      }}
                      size="m"
                      variant="yellow"
                    >
                      Approve
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        setIsApproved(false);
                        setOpenNote(true);
                      }}
                      variant="red"
                      size="m"
                    >
                      Reject
                    </CustomButton>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Assign Technician Modal */}
        {takeAction && (
          <AssignTechnicianMoveInModal
            showModal={showRejectionModal}
            maintenance_id={maintenance_id}
            property={property}
            type="Reject and Assign"
            noteLabel="Reason for Rejection"
            noteValidation={true}
            title="Reject And Assign Technician"
            assignOnUIRCP1Reject={async (technician, note) =>
              await reviewReport("Rejected", technician, note)
            }
            onClose={() => {
              setShowRejectionModal(false);
            }}
          />
        )}

        {/* Spare Part Quotation Modal */}
        <SparePartQuoteModal
          showModal={addQuotation}
          onClose={() => setAddQuotation(false)}
          type="move_in"
          maintenance_id={maintenance_id}
          maintenanceType={maintenanceType}
          unitCard={unitCard}
          user={user}
          data={logType === "quoatation" ? item?.data : null}
          currentLog={item}
          // edit={quote?.status === "Rejected"}
          edit={currentStatus === "Rejected" && logType === "quoatation"}
          isT1={isT1}
        />
      </div>

      {/* Note Modal */}
      <NoteModal
        showModal={showNoteModal}
        title="Additional Notes"
        btnText="Approve"
        actionWithNote={async (note) =>
          await reviewReport("Approved", "", note)
        }
        onCancel={() => setShowNoteModal(false)}
      />

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />

      {/* Adding Note Modal */}
      <NoteModal
        showModal={openNote}
        title="Note"
        btnText={isApproved ? "Approve" : "Reject"}
        onCancel={() => setOpenNote(false)}
        actionWithNote={respondAction}
      />
    </div>
  );
};
/* Component ends */

export default URICP1Log;
