/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { invoiceDetailsByType, navigateToInvoiceByType } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  updateTenantApplication,
  clearInstalments,
  createTenantInitialAgreement,
  resendTenantInvitation,
  cancelTenantInvitation,
} from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  WarningRoundedOutlineYellow,
  EyeFilledBlue,
  ExcalmatoryYellow,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "./sub-component/Header";
import PropertyDetail from "./sub-component/PropertyDetail";
import BasicDetails from "./sub-component/BasicDetails";
import IDVerification from "./sub-component/IDVerification";
import HouseholdMembers from "./sub-component/HouseholdMembers";
import Vehicles from "./sub-component/Vehicles";
import Pets from "./sub-component/Pets";
import RentalHistory from "./sub-component/RentalHistory";
import ApplicationFee from "./sub-component/ApplicationFee";
import RentDetails from "./sub-component/RentDetails";
import InitialAgreement from "./sub-component/InitialAgreement";
import UtilityDetail from "./sub-component/UtilityDetail";
import ContractDetail from "./sub-component/ContractDetail";
import ContractSignature from "./sub-component/ContractSignature";
import MoveIn from "./sub-component/MoveIn";
import Keys from "./sub-component/Keys";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CreateInstallmentModal from "../CreateInstallmentModal/CreateInstallmentModal";
import CommercialDetails from "./sub-component/CommercialDetails";
import ESignDocModal from "../ESignDocModal/ESignDocModal";
import FinalAgreement from "./sub-component/FinalAgreement";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import ApproveOrRejectApplication from "./sub-component/ApproveOrRejectApplication";
import ApplicationRejectedStatus from "./sub-component/ApplicationRejectedStatus";
import StartEarlyMoveout from "./sub-component/StartEarlyMoveout";
import ReSendUtilityForReview from "./sub-component/ReSendUtilityForReview";
import ForceMoveOut from "./sub-component/ForceMoveOut";
import SignInitialAgreement from "./sub-component/SignInitialAgreement";
import SignFinalAgreement from "./sub-component/SignFinalAgreement";
/* Import local pages and component ends */

import "./style.scss";
import { Table } from "react-bootstrap";
import moment from "moment";
import Disability from "./sub-component/Disability";
import UnitDetails from "./sub-component/UnitDetails";
import ApplicationCurrentStatus from "./sub-component/ApplicationCurrentStatus";

/* Component starts */
const NewTenantPMReview = (props) => {
  /* Props destructuring starts */
  const {
    getApplication,
    application,
    application_id,
    sequence,
    sequenceNumber = [],
    utilityRejected,
    property,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSetInstallment, setShowSetInstallment] = useState(false);

  const [openInitAgreement, setOpenInitAgreement] = useState(false);
  const [openFinlAgreement, setOpenFinlAgreement] = useState(false);

  const [action, setAction] = useState();
  const [invitationConfirmModalText, setInvitationConfirmModalText] = useState({
    title: "",
    description: "",
  });
  const [showInvitationConfirmation, setShowInvitationConfirmation] =
    useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const propertyDetail = useSelector(getPropertyDetail);
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isACC = localStorage.getItem("user_role") === "Accountant";
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On taking action on tenant application data only. Accepting or Rejecting
  const onAction = async (data) => {
    // Creating or collecting payload data to be sent
    const payload = {
      info_status_update: true,
      applicationId: application_id,
      property_id: propertyId,
      ...data,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantApplication(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const generateInstallment = async (payload) => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        createTenantInitialAgreement({ property_id: propertyId, ...payload })
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          setShowSetInstallment(false);
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          setShowSetInstallment(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onInvitaionAction = async () => {
    setShowInvitationConfirmation(false);
    // Backend response. Try, Catch
    try {
      let result = {};
      if (action === "resend") {
        result = await dispatch(
          resendTenantInvitation(application?.application?.invitation_id)
        );
      } else {
        result = await dispatch(
          cancelTenantInvitation(application?.application?.invitation_id)
        );
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          getApplication();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(fetchUserRoles());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="new-tenant-review-module-wrapper">
      <p className="font_xl fw_6 top-heading">Tenant Onboarding Application</p>

      <div className="details-wrapper">
        <div className="my-2">
          <UnitDetails details={application?.application?.unit} index="1" />
        </div>
        {application &&
          sequence &&
          sequence.map((seq) => {
            switch (seq) {
              case "basic_details":
                return (
                  <>
                    <BasicDetails
                      details={application?.basic_details}
                      index={sequenceNumber.indexOf("basic_details") + 2}
                    />
                  </>
                );

              case "cancel_resend_invitation":
                return (
                  <>
                    <div className="invitaion-action-btn-wrapper">
                      <CustomButton
                        onClick={() => {
                          setAction("resend");
                          setInvitationConfirmModalText({
                            title: "Confirm Resend",
                            description: "Are you sure you want to resend ?",
                          });
                          setShowInvitationConfirmation(true);
                        }}
                        size="l"
                        className=""
                      >
                        Resend Invitation
                      </CustomButton>
                      <CustomButton
                        onClick={() => {
                          setAction("cancel");
                          setInvitationConfirmModalText({
                            title: "Confirm Cancel",
                            description: "Are you sure you want to cancel ?",
                          });
                          setShowInvitationConfirmation(true);
                        }}
                        size="l"
                        variant="red"
                        className=""
                      >
                        Cancel Invitaion
                      </CustomButton>
                    </div>
                  </>
                );

              case "invitation_canceled":
                return (
                  <>
                    <div className="fw_6 txt-inv-cancelled status-txt">
                      <p className="font_xl fw_4 font_grey">
                        Reason :{" "}
                        <span className="font_xl fw_6 font_red">
                          Invitation Cancelled
                        </span>
                      </p>
                    </div>
                  </>
                );

                case "invitation_expired":
                return (
                  <>
                    <div className="fw_6 txt-inv-cancelled status-txt">
                      <p className="font_xl fw_4 font_grey">
                        Reason :{" "}
                        <span className="font_xl fw_6 font_red">
                          Invitation Expired
                        </span>
                      </p>
                    </div>
                  </>
                );

              case "pending_tenant_inv_confirmation":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending Invitation Confirmation
                    </h2> */}
                    <ApplicationCurrentStatus message="Pending Invitation Confirmation" />
                  </>
                );

              case "pending_review_message":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending Application Review
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending Application Review" />
                  </>
                );

              case "pending_tenant_application":
                return (
                  <>
                    {application?.application?.step_to_show ===
                    "application_fee" ? (
                      <ApplicationCurrentStatus message="Pending Application Fee Payment" />
                    ) : (
                      <ApplicationCurrentStatus message="Pending Tenant Application" />
                    )}
                  </>
                );

              case "pending_tenant_action":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending Tenant Action
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending Tenant Action" />
                  </>
                );

              case "commercial_details":
                return (
                  <>
                    <CommercialDetails
                      approval={
                        (isOwner || isPM) &&
                        application.application.status !==
                          "reservor-rejected" &&
                        application.application.status !==
                          "reservor-data-rejected"
                      }
                      onAction={onAction}
                      details={application?.commercial_details}
                      index={sequenceNumber.indexOf("commercial_details") + 2}
                    />
                  </>
                );

              case "ids":
                return (
                  <>
                    <IDVerification
                      data={application?.ids}
                      approval={
                        (isOwner || isPM) &&
                        application.application.status !==
                          "reservor-rejected" &&
                        application.application.status !==
                          "reservor-data-rejected"
                      }
                      onAction={onAction}
                      index={sequenceNumber.indexOf("ids") + 2}
                    />
                  </>
                );

              case "household_members":
                return (
                  <>
                    {application?.household_members?.length > 0 && (
                      <HouseholdMembers
                        data={application?.household_members}
                        approval={
                          (isOwner || isPM) &&
                          application.application.status !==
                            "reservor-rejected" &&
                          application.application.status !==
                            "reservor-data-rejected"
                        }
                        onAction={onAction}
                        index={sequenceNumber.indexOf("household_members") + 2}
                      />
                    )}
                  </>
                );

              case "disability":
                return (
                  <>
                    <Disability
                      data={application?.disability}
                      index={sequenceNumber.indexOf("disability") + 2}
                    />
                  </>
                );

              case "vehicles":
                return (
                  <>
                    {application?.vehicles?.length > 0 && (
                      <Vehicles
                        data={application?.vehicles}
                        approval={
                          (isOwner || isPM) &&
                          application.application.status !==
                            "reservor-rejected" &&
                          application.application.status !==
                            "reservor-data-rejected"
                        }
                        onAction={onAction}
                        index={sequenceNumber.indexOf("vehicles") + 2}
                      />
                    )}
                  </>
                );

              case "pets":
                return (
                  <>
                    {application?.pets?.length > 0 && (
                      <Pets
                        data={application?.pets}
                        approval={
                          (isOwner || isPM) &&
                          application.application.status !==
                            "reservor-rejected" &&
                          application.application.status !==
                            "reservor-data-rejected"
                        }
                        onAction={onAction}
                        index={sequenceNumber.indexOf("pets") + 2}
                      />
                    )}
                  </>
                );

              case "rental_history":
                return (
                  <>
                    {application?.rental_history?.length > 0 && (
                      <RentalHistory
                        data={application?.rental_history}
                        approval={
                          (isOwner || isPM) &&
                          application.application.status !==
                            "reservor-rejected" &&
                          application.application.status !==
                            "reservor-data-rejected"
                        }
                        onAction={onAction}
                        index={sequenceNumber.indexOf("rental_history") + 2}
                      />
                    )}
                  </>
                );

              case "application_fee":
                return (
                  <>
                    {application?.application_fee && (
                      <ApplicationFee
                        propertySettings={application?.property_settings}
                        invoiceDetails={application.application_fee}
                        index={
                          sequenceNumber?.indexOf("application_fee") === 2
                            ? sequenceNumber?.indexOf("application_fee") + 1
                            : sequenceNumber.indexOf("application_fee") + 2
                        }
                        property={property}
                        getApplication={getApplication}
                      />
                    )}
                  </>
                );

              case "pending_review":
                return (
                  <>
                    <ApproveOrRejectApplication
                      getApplication={getApplication}
                      application_id={application_id}
                      application={application}
                    />
                  </>
                );

              case "cancel_tenant_application":
                return (
                  <>
                    <ApproveOrRejectApplication
                      getApplication={getApplication}
                      application_id={application_id}
                      application={application}
                      canApprove={false}
                      canSendForReview={false}
                    />
                  </>
                );

              case "application_rejected":
                return (
                  <>
                    <ApplicationRejectedStatus
                      application={application?.application}
                    />
                  </>
                );

              case "create_instalments":
                return (
                  <>
                    <div className="rent-installment-wrapper">
                      <div className="box bg_grey text-center">
                        <TextButton
                          onClick={async () => {
                            await dispatch(clearInstalments());
                            setShowSetInstallment(true);
                          }}
                          className="font_m fw_5"
                        >
                          {/* + Create Installment */}+ Add Contract Terms
                        </TextButton>
                      </div>
                    </div>
                  </>
                );

              case "create_instalments_message":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending Initial Agreement
                    </h2> */}
                    <ApplicationCurrentStatus message="Pending Initial Agreement" />
                  </>
                );

              case "installments":
                return (
                  <>
                    <RentDetails
                      property={property}
                      canEdit={
                        (isOwner || isPM) &&
                        application?.can_edit_installment_plan
                      }
                      canSend={
                        (isOwner || isPM) &&
                        application?.can_send_installment_plan
                      }
                      instalments={application?.installments}
                      data={application?.installments}
                      instalmentPlan={application?.installment_plan}
                      application_id={application_id}
                      setShowSetInstallment={setShowSetInstallment}
                      currency={
                        application?.installment_plan?.currency ||
                        property?.currency
                      }
                      canAttachCheque={
                        application.can_attach_cheque &&
                        ((propertyDetail?.mgmt_model === 0 && isOwner) ||
                          (propertyDetail?.mgmt_model !== 0 && isACC))
                      }
                      settings={application?.property_settings}
                      getApplication={getApplication}
                      depositData={application?.installment_plan?.deposit_data}
                      onUpdateChequeCallback={getApplication}
                      index={sequenceNumber.indexOf("installment_plan") + 2}
                    />
                  </>
                );

              case "pending_tenant_sign":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      {/* Pending Tenant Sign *
                      Pending Initial Agreement Tenant Signature
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending Initial Agreement Tenant Signature" />
                  </>
                );

              case "pending_acc_cheque_attach":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending{" "}
                      {propertyDetail?.mgmt_model === 0
                        ? "Owner"
                        : "Accountant"}{" "}
                      To Attach Cheque To Installments
                    </h2> */}

                    <ApplicationCurrentStatus
                      message={`Pending
  ${propertyDetail?.mgmt_model === 0 ? "Owner" : "Accountant"}
  To Attach Cheque To Installments`}
                    />
                  </>
                );

              case "pending_approver_sign":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending PM or Owner Sign
                    </h2> */}
                    <ApplicationCurrentStatus message="Pending PM or Owner Sign" />
                  </>
                );

              case "pending_tenant_sign_initial_agreement":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                        Pending final agreement signature
                      </h2> */}

                    <ApplicationCurrentStatus message="Pending Initial Contract Tenant Signature" />
                  </>
                );

              case "sign_initial_agreement":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt mt-4">
                      Pending Owner/ Property Manager Signature
                    </h2> */}

                    <ApplicationCurrentStatus
                      message={`Pending ${
                        (isPM && "Property Manager") || (isOwner && "Owner")
                      } Signature`}
                      // "Pending Owner/ Property Manager Signature"
                    />

                    <div className="initAgreementTemplate bg_grey">
                      <p className="font_m fw_5">
                        Review and Sign the Initial Agreement
                      </p>
                      <div className="init-agreement-eye">
                        <img
                          onClick={() => {
                            setOpenInitAgreement(true);
                          }}
                          src={EyeFilledBlue}
                          alt="View Agreement"
                          className="icon-m"
                        />

                        <ESignDocModal
                          showModal={openInitAgreement}
                          type="initial_agreement"
                          application_id={application_id}
                          onCompleted={async () => {
                            dispatch(setLoading(true));
                            setTimeout(async () => {
                              console.log("Signed Successfully");
                              await getApplication();
                              navigate(-1);
                            }, 2000);
                            setOpenInitAgreement(false);
                          }}
                          onClose={() => {
                            setOpenInitAgreement(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                );

              case "initial_agreement":
                return (
                  <>
                    <InitialAgreement
                      application={application?.application}
                      index={sequenceNumber.indexOf("initial_agreement") + 2}
                      agreement={
                        application?.application?.initial_agreement_pdf
                      }
                      auditLog={
                        application?.application?.initial_agreement_audit_logs
                      }
                    />
                  </>
                );

              case "contract_detail":
                return (
                  <>
                    <ContractDetail
                      invoices={application.billing_invoices}
                      index={sequenceNumber.indexOf("contract") + 2}
                      settings={application.property_settings}
                      property={property}
                      getApplication={getApplication}
                    />
                  </>
                );

              case "tenant_invoice_payment":
                return (
                  <>
                    <ApplicationCurrentStatus
                      message={
                        isOwner || isACC
                          ? "Please update payments for Deposit(s), Commission, and First Instalment."
                          : "Waiting for Tenant Contract Payment"
                      }
                    />
                  </>
                );

              case "start_early_moveout":
                return (
                  <>
                    <StartEarlyMoveout
                      application_id={application_id}
                      getApplication={getApplication}
                    />
                  </>
                );

              case "early_moveout_started":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Early Move Out Started
                    </h2> */}
                    <ApplicationCurrentStatus message="Move Out Initiated" />
                    {/* Pending Owner/ Property Manager Signature */}
                  </>
                );

              case "invoices_getting_created":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Please wait, invoices are getting generated. Retry in some
                      time.
                    </h2> */}

                    <ApplicationCurrentStatus
                      message="Please wait, invoices are getting generated. Retry in some
                      time."
                    />
                  </>
                );

              case "utilities":
                return (
                  <>
                    {application?.utilities?.length === 0 && (
                      // <h2 className="text-center font_yellow fw_6 status-txt">
                      //   Pending Utility Registeration Documents
                      // </h2>

                      <ApplicationCurrentStatus message="Pending Utility Registeration Documents" />
                    )}

                    {application?.utilities?.length > 0 && (
                      <UtilityDetail
                        data={application?.utilities}
                        application_id={application_id}
                        getApplication={getApplication}
                        approval={(isOwner || isPM) && !utilityRejected()}
                        index={sequenceNumber.indexOf("utilities") + 2}
                      />
                    )}
                  </>
                );

              case "can_send_utility_for_review":
                return (
                  <>
                    <ReSendUtilityForReview
                      application_id={application_id}
                      getApplication={getApplication}
                    />
                  </>
                );

              case "can_send_utility_for_review_message":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending tenant utility approval
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending tenant utility approval" />
                  </>
                );

              case "pending_tenant_utility_submittion":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending tenant utility submission
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending tenant utility submission" />
                  </>
                );

              case "pending_tenant_sign_final_agreement":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt">
                      Pending final agreement signature
                    </h2> */}

                    <ApplicationCurrentStatus message="Pending Final Contract Tenant Signature" />
                  </>
                );

              case "sign_final_agreement":
                return (
                  <>
                    {/* <h2 className="text-center font_yellow fw_6 status-txt mt-4">
                      Pending Owner/ Property Manager Signature
                    </h2> */}

                    <ApplicationCurrentStatus
                      message={`Pending ${
                        (isPM && "Property Manager") || (isOwner && "Owner")
                      } Signature`}
                      // "Pending Owner/ Property Manager Signature"
                    />

                    <div className="initAgreementTemplate bg_grey">
                      <p className="font_m fw_5">
                        Review and Sign the Final Agreement
                      </p>
                      <div className="init-agreement-eye">
                        <img
                          onClick={() => {
                            setOpenFinlAgreement(true);
                          }}
                          src={EyeFilledBlue}
                          alt=""
                          className="icon-m"
                        />

                        <ESignDocModal
                          showModal={openFinlAgreement}
                          type="final_agreement"
                          application_id={application_id}
                          onCompleted={async () => {
                            dispatch(setLoading(true));
                            setTimeout(async () => {
                              console.log("Signed Successfully");
                              await getApplication();
                            }, 2000);
                            setOpenFinlAgreement(false);
                          }}
                          onClose={() => {
                            setOpenFinlAgreement(false);
                          }}
                        />
                      </div>
                    </div>
                  </>
                );

              case "final_agreement":
                return (
                  <>
                    <FinalAgreement
                      application={application.application}
                      index={sequenceNumber.indexOf("final_agreement") + 2}
                      agreement={application?.application.final_agreement_pdf}
                      auditLog={
                        application?.application.final_agreement_audit_logs
                      }
                    />
                  </>
                );

              case "maintenance":
                return (
                  <>
                    <MoveIn
                      index={sequenceNumber.indexOf("maintenance") + 2}
                      maintenance={application?.maintenance}
                    />

                    {application?.maintenance?.status !== "Completed" && (
                      // <p className="text-center font_yellow fw_6 status-txt mt-4">
                      // The unit is undergoing final preparations. Our
                      // maintenance team is working diligently to ensure it's
                      // move-in ready as soon as possible.
                      // </p>
                      <ApplicationCurrentStatus
                        message={`Unit Under Maintenance`}
                      />
                    )}
                  </>
                );

              case "keys":
                return (
                  <>
                    <Keys
                      details={application?.key}
                      index={sequenceNumber.indexOf("keys") + 2}
                    />

                    {application?.key?.status !== "reservor-key-collected" &&
                      application?.key?.status !==
                        "reservor-rejected-key-collection" &&
                      application?.key?.status !== "pending_key_dispatch" && (
                        // <h2 className="text-center font_yellow fw_6 status-txt mt-2">
                        //   Pending Keys Acceptance By Tenant
                        // </h2>

                        <ApplicationCurrentStatus message="Pending Keys Acceptance By Tenant" />
                      )}

                    {application?.key?.status === "pending_key_dispatch" && (
                      // <h2 className="text-center font_yellow fw_6 status-txt mt-2">
                      //   Pending Keys Acceptance By Tenant
                      // </h2>

                      <ApplicationCurrentStatus message="Pending for Contract Start Date" />
                    )}
                  </>
                );

              case "force_move_out":
                return (
                  <>
                    <ForceMoveOut
                      contract_id={application?.contract_id}
                      getApplication={getApplication}
                    />
                  </>
                );
            }
          })}
      </div>

      <SuccessModal
        showModal={showSuccessModal}
        title="Success!"
        description={message}
        onClose={() => {
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        title="Error!"
        description={message}
        onClose={() => setShowErrModal(false)}
      />

      {/* Error Modal */}
      <CreateInstallmentModal
        showModal={showSetInstallment}
        application_id={application_id}
        target_rent_price={application?.application?.unit?.target_rent_price}
        onClose={() => setShowSetInstallment(false)}
        instalmentPlan={application.installment_plan}
        declareInstallment={generateInstallment}
        currency={application?.application?.unit?.currency}
        property={property}
      />

      <ConfirmationModal
        showModal={showInvitationConfirmation}
        title={invitationConfirmModalText.title}
        description={invitationConfirmModalText.description}
        onClose={() => {
          setShowInvitationConfirmation(false);
        }}
        onConfirm={onInvitaionAction}
      />
    </div>
  );
};
/* Component ends */

export default NewTenantPMReview;
