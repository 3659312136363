/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  assignIncidentToSO,
  assignedBackIncidentToSM,
  fetchCompanyListBasedProperty,
  fetchSecurityOfficerBasedOnProperty,
  fetchTeamMemberBasedOnCompany,
  fetchTenantUnitsBasedPropertyId,
  getCompaniesList,
  getCompanyMembersList,
  getSecurityOfficersList,
  getTenantsLists,
  issueViolationForIncidentByPM,
  updateIncidentEscalateBySM,
  updateIncidentWithResolved,
} from "../../../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../../components/ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../../components/ui/input/CustomSelectInput/CustomSelectInput";
import GLOBAL from "../../../../setup/constants/global";
import SuccessModal from "../../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../components/ui/modal/ErrorModal/ErrorModal";
import CustomFileInput from "../../../../components/ui/input/CustomFileInput/CustomFileInput";
import TextEditor from "../../../../components/ui/other/TextEditor/TextEditor";
import CustomDateInput from "../../../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../../components/ui/input/CustomTextInput/CustomTextInput";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
import NewHTMLTextEditor from "../../../../components/ui/other/NewHTMLTextEditor/NewHTMLTextEditor";

/* Import local pages and component ends */

/* Component starts */
const T3IncidentReportForm = (props) => {
  /* Props destructuring starts */
  const { role, incident } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedOfficer, setSelectedOfficer] = useState();
  const [reason, setReason] = useState("");

  // const [violationIncident, setViolationIncident] = useState("Tenants");
  const [vioationDesc, setvioationDesc] = useState("");
  const [date, setDate] = useState(new Date());

  const [descVal, setDescVal] = useState({ value: null });
  const [selectedUnitNo, setSelectedUnitNo] = useState();

  const [filterCompanyList, setFilterCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedMemberList, setSelectedMemberList] = useState();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const uploadDoc = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const property = useSelector(getPropertyDetail);
  const securityOfficersList = useSelector(getSecurityOfficersList);
  const tenantList = useSelector(getTenantsLists);

  const companyList = useSelector(getCompaniesList);
  const teamMembersList = useSelector(getCompanyMembersList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const initialFormValues = {
    comment: "",
    fullName: "",
    company: "",
    title: "",
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  //Loading all Team Members For Selected Company Name
  const loadTeamMembers = async () => {
    if (selectedCompany != null) {
      setSelectedMemberList(null);
      let payload = {
        propertyId: property?._id,
        compId: selectedCompany?.company_id,
      };
      await dispatch(fetchTeamMemberBasedOnCompany(payload));
    }
  };

  const loadSecurityOfficersList = () => {
    dispatch(fetchSecurityOfficerBasedOnProperty(property?._id));
  };

  const onSubmit = async (values) => {
    let payload = {};

    if (role === "Security Manager") {
      if (reason === "resolved") {
        payload["security_incident_id"] = incident?._id;
        payload["status"] = "Resolved";
        payload["note"] = values.comment;
      } else if (reason === "need-more-info") {
        payload["security_incident_id"] = incident?._id;
        payload["note"] = values.comment;
        payload["assigned_to"] = selectedOfficer?._id || null;
      } else {
        payload["note"] = values.comment;
        payload["security_incident_id"] = incident?._id;
      }
    } else {
      if (reason === "resolved") {
        payload["security_incident_id"] = incident?._id;
        payload["note"] = values.comment;
      } else if (reason === "need-more-info-sm") {
        payload["security_incident_id"] = incident?._id;
        payload["note"] = values.comment;
      } else {
        console.log("HIII");
        if (incident?.violator === "Tenants") {
          payload["security_incident_id"] = incident?._id;
          payload["title"] = values.title;
          payload["violator"] = incident?.violator;
          payload["end_date"] = date;
          payload["violator_id"] = selectedUnitNo?.tenant_id || null;
          payload["unit_id"] = selectedUnitNo?.unit_id || null;

          console.log(payload, "TENANTS");
        }

        if (incident?.violator === "Teams") {
          payload["security_incident_id"] = incident?._id;
          payload["title"] = values.title;
          payload["violator"] = incident?.violator;
          payload["end_date"] = date;
          payload["team_member_id"] = incident?.team_member_id;
          payload["property_id"] = property?._id;

          console.log(payload, "TEAMS");
        }

        if (incident?.violator === "Others") {
          payload["security_incident_id"] = incident?._id;
          payload["title"] = values.title;
          payload["violator"] = incident?.violator;
          payload["end_date"] = date;
          payload["violator_name"] = values.fullName;
          payload["company_name"] = values.company || "";
          payload["property_id"] = property?._id;

          console.log(payload, "OTHERS");
        }

        if (vioationDesc === "Draft") {
          payload["description"] = descVal.value;
        } else {
          payload["document"] = uploadDoc.current;
        }
      }
    }

    console.log("SO_TEST", payload);

    // Backend response. Try, Catch
    let result;
    try {
      if (
        reason === "resolved" &&
        (role === "Security Manager" || "Property manager")
      ) {
        console.log("RESOLEVD");
        result = await dispatch(updateIncidentWithResolved(payload));
      } else if (reason === "need-more-info" && role === "Security Manager") {
        console.log("NEED-MORE-BY SM");
        result = await dispatch(assignIncidentToSO(payload));
      } else if (reason === "escalated-PM") {
        console.log("ESCALATE-PM");
        result = await dispatch(updateIncidentEscalateBySM(payload));
      } else if (
        reason === "need-more-info-sm" &&
        role === "Property Manager"
      ) {
        console.log("NEED-MORE-BY-PM --> ASSIGN SM");
        result = await dispatch(assignedBackIncidentToSM(payload));
      } else {
        console.log("CLOSE-VIO ==> PM");
        // result = await dispatch(issueViolationForIncidentByPM(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onSuccess = () => {
    navigate(-1);
  };

  const handleClose = () => {
    navigate(-1);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    loadSecurityOfficersList();
  }, []);

  useEffect(() => {
    dispatch(fetchTenantUnitsBasedPropertyId());
    dispatch(fetchCompanyListBasedProperty());
  }, [dispatch]);

  // Loading All Existing Tenants Details
  useEffect(() => {
    if (tenantList?.length > 0) {
      setSelectedUnitNo(tenantList[0]);
    }
  }, [tenantList]);

  // Setting selected Company after loading Companies
  // useEffect(() => {
  //   if (companyList?.length > 0) {
  //     setFilterCompanyList(
  //       companyList?.filter((item) => item.role !== "Owner")
  //     );
  //   }
  // }, [companyList]);

  // useEffect(() => {
  //   if (filterCompanyList?.length > 0) {
  //     setSelectedCompany(companyList);
  //   }
  // }, [filterCompanyList]);

  // Loading All Team Members Based on selected Company
  // useEffect(() => {
  //   loadTeamMembers();
  // }, [selectedCompany]);

  // Setting selected Team Member after loading Companies List
  // useEffect(() => {
  //   if (teamMembersList?.length > 0) {
  //     setSelectedMemberList(teamMembersList[0]);
  //   }
  // }, [teamMembersList]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="report-form">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        //   validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper p-3">
              {/* Creating new Security Incident Report */}
              <>
                {/* Radio select the Reason */}
                {role === "Security Manager" ? (
                  <>
                    <div className="form-group form-group-full d-flex justify-content-between  ">
                      <CustomRadioInput
                        label="Need More Information"
                        onSelecting={() => {
                          setReason("need-more-info");
                        }}
                        isSelected={reason === "need-more-info"}
                      />
                      <CustomRadioInput
                        label="Escalated It To Property Manager"
                        onSelecting={() => {
                          setReason("escalated-PM");
                        }}
                        isSelected={reason === "escalated-PM"}
                      />
                    </div>
                    <div className="select-reason">
                      <CustomRadioInput
                        label="Resolved"
                        onSelecting={() => {
                          setReason("resolved");
                        }}
                        isSelected={reason === "resolved"}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group form-group-full d-flex justify-content-between  ">
                      <CustomRadioInput
                        label="Need More Information"
                        onSelecting={() => {
                          setReason("need-more-info-sm");
                        }}
                        isSelected={reason === "need-more-info-sm"}
                      />
                      <CustomRadioInput
                        label="Resolved"
                        onSelecting={() => {
                          setReason("resolved");
                        }}
                        isSelected={reason === "resolved"}
                      />
                    </div>
                    <div className="select-reason">
                      <CustomRadioInput
                        label="Issue Violation"
                        onSelecting={() => {
                          setReason("issue-violation");
                        }}
                        isSelected={reason === "issue-violation"}
                      />
                    </div>
                  </>
                )}
                {/* Select Officer */}
                {reason === "need-more-info" && (
                  <div className="form-group">
                    <p className="mt-3">
                      Select Officers{" "}
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        *
                      </span>
                    </p>
                    <CustomSelectInput
                      name="officer"
                      label="Officers"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) =>
                        `${item?.first_name} ${item?.last_name}`
                      }
                      setValue={setSelectedOfficer}
                      options={securityOfficersList}
                      value={selectedOfficer}
                      formikState={false}
                      emptyOptionText="Select Officer"
                      emptyOption={true}
                    />
                  </div>
                )}

                {/* Text Area for Reason */}
                {(reason === "need-more-info" ||
                  "resolved" ||
                  "need-more-info-sm") &&
                  reason !== "issue-violation" && (
                    <div className="form-group form-group-full mt-4">
                      <CustomTextArea
                        name="comment"
                        label="Comment"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.comment}
                        errors={errors.comment}
                        touched={touched.comment}
                        required={true}
                      />
                    </div>
                  )}

                {reason === "issue-violation" && (
                  <>
                    {/* <hr />
                    <div className="form-group form-group-full mt-4 d-flex flex-direction-row gap-5 mb-4">
                      <CustomRadioInput
                        label="Tenants"
                        onSelecting={() => {
                          setViolationIncident("Tenants");
                        }}
                        isSelected={violationIncident === "Tenants" || ""}
                      />

                      <CustomRadioInput
                        label="Teams"
                        onSelecting={() => {
                          setViolationIncident("Teams");
                        }}
                        isSelected={violationIncident === "Teams" || ""}
                      />

                      <CustomRadioInput
                        label="Others"
                        onSelecting={() => {
                          setViolationIncident("Others");
                        }}
                        isSelected={violationIncident === "Others" || ""}
                      />
                    </div> */}

                    {/* If Selected Tenant */}
                    {/* {incident?.violator === "Tenants" && (
                      <>
                        <div className="form-group mb-4">
                          <CustomSelectInput
                            name="unit_num"
                            label="Select Tenant Unit"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) =>
                              `${item?.unit_name} - ${item?.tenant_name}`
                            }
                            setValue={setSelectedUnitNo}
                            options={tenantList}
                            value={selectedUnitNo}
                            formikState={false}
                            emptyOption={false}
                          />
                        </div>
                      </>
                    )} */}

                    {/* {incident?.violator === "Teams" && (
                      <>
                        <div className="form-group mb-3">
                          <CustomSelectInput
                            name="companyName"
                            label="Select Company Name"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) => item?.company_name}
                            setValue={setSelectedCompany}
                            options={filterCompanyList}
                            value={selectedCompany}
                            formikState={false}
                            emptyOption={true}
                          />
                        </div>

                        <div className="form-group mb-4">
                          <CustomSelectInput
                            name="teamMember"
                            label="Team Member Name"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) =>
                              `${item?.mem_name} - ${item?.mem_role}`
                            }
                            setValue={setSelectedMemberList}
                            options={teamMembersList}
                            value={selectedMemberList}
                            formikState={false}
                            emptyOption={false}
                          />
                        </div>
                      </>
                    )} */}

                    {/* If Selected Others */}
                    {/* {incident?.violator === "Others" && (
                      <>
                        <div className="form-group mb-3">
                          <CustomTextInput
                            name="fullName"
                            label="Full Name"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.fullName}
                            errors={errors.fullName}
                            value={values.fullName}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <CustomTextInput
                            name="company"
                            label="Company"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.company}
                            errors={errors.company}
                            value={values.company}
                            required={false}
                          />
                        </div>
                      </>
                    )} */}

                    <p className="font_m font_grey w_100">Violations</p>
                    {/* Input for Subject */}
                    <div className="form-group mb-3">
                      <CustomTextInput
                        name="title"
                        label="Enter Subject"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.title}
                        errors={errors.title}
                        value={values.title}
                      />
                    </div>

                    {/* Input for Date */}
                    <div className="form-group mb-4">
                      <CustomDateInput
                        label="Enter Date"
                        value={date}
                        customOnchange={(date) => setDate(date)}
                      />
                    </div>

                    <div className="form-group form-group-full mt-2 d-flex flex-direction-row gap-5 mb-4">
                      <CustomRadioInput
                        label="Draft"
                        onSelecting={() => {
                          setvioationDesc("Draft");
                        }}
                        isSelected={vioationDesc === "Draft" || ""}
                      />

                      <CustomRadioInput
                        label="Upload Image"
                        onSelecting={() => {
                          setvioationDesc("Upload-Image");
                        }}
                        isSelected={vioationDesc === "Upload-Image" || ""}
                      />
                    </div>

                    {/* Input for Description */}
                    {vioationDesc === "Draft" && (
                      <div className="form-group form-group-full">
                        {/* <TextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                        /> */}
                        <NewHTMLTextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                          isEmptyContent={isEmptyContent}
                          setIsEmptyContent={setIsEmptyContent}
                          isEditorFocused={isEditorFocused}
                          setIsEditorFocused={setIsEditorFocused}
                        />

                        {(isEmptyContent && (
                          <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                        )) ||
                          (isEditorFocused && (
                            <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                          ))}
                      </div>
                    )}

                    {/* Input for Upload ID */}
                    {vioationDesc === "Upload-Image" && (
                      <div className="form-group">
                        <CustomFileInput
                          label="Upload Image"
                          name="uploadDoc"
                          files={uploadDoc}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.uploadDoc}
                          touched={touched.uploadDoc}
                          values={values.uploadDoc}
                          required={true}
                          formikState={false}
                          acceptable={true}
                          acceptType="image/*,video/*"
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            </div>

            {(reason === "resolved" || "assign") && (
              <div className="form-btn-wrapper">
                <>
                  <CustomButton
                    type="button"
                    size="l"
                    onClick={() => handleClose()}
                    className="btn1"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    disable={!(dirty && isValid)}
                    size="l"
                  >
                    Submit
                  </CustomButton>
                </>
              </div>
            )}
          </form>
        )}
      </Formik>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default T3IncidentReportForm;
