/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchViolationsList,
  getViolationsList,
} from "../../../../../../setup/store/slices/violationSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../../../setup/store/slices/masterSlice";
import {
  fetchUser,
  getUser,
} from "../../../../../../setup/store/slices/authSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */

/* Import react bootstrap component ends */

/* Import image and SVG starts */

/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import Searchbar from "../../../../../ui/other/Searchbar/Searchbar";
import TabButton from "../../../../../ui/other/TabButton/TabButton";

import { buttonList } from "./helper";
import TenantsViolations from "./sub-components/TenantsViolations";
import TeamsViolations from "./sub-components/TeamsViolations";
import OthersViolations from "./sub-components/OthersViolations";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ClientViolationsTable = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState(GLOBAL.VIOLATION_TO.TENANT);
  const [buttonLists, setButtonLists] = useState([]);
  const [role, setRole] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId } = useParams();
  const property = useSelector(getPropertyDetail);

  const violationLists = useSelector(getViolationsList);
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  let payload = {};
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadVioltionList = () => {
    if (currentKey === GLOBAL.VIOLATION_TO.TENANT) {
      payload = {
        property_id: propertyId,
        violator: GLOBAL.VIOLATION_TO.TENANT,
        page: currentPage,
      };
      dispatch(fetchViolationsList(payload));
    } else if (currentKey === GLOBAL.VIOLATION_TO.TEAM) {
      payload = {
        property_id: propertyId,
        violator: GLOBAL.VIOLATION_TO.TEAM,
        page: currentPage,
      };
      dispatch(fetchViolationsList(payload));
    } else {
      payload = {
        property_id: propertyId,
        violator: GLOBAL.VIOLATION_TO.OTHERS,
        page: currentPage,
      };
      dispatch(fetchViolationsList(payload));
    }
  };

  const onTabSelect = (key) => {
    // const pathnameArr = pathname.split("/");
    // pathnameArr.pop();
    // navigate(pathnameArr.join("/") + "/" + key);
    setCurrentKey(key);
    setCurrentPage(1);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (property) {
      if (property?.mgmt_model === 2) {
        setButtonLists(buttonList);
      } else {
        setButtonLists([
          { key: GLOBAL.VIOLATION_TO.TENANT, label: "Tenants" },
          { key: GLOBAL.VIOLATION_TO.OTHERS, label: "Others" },
        ]);
      }
    }
  }, [property]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);

  useEffect(() => {
    loadVioltionList();
  }, [currentKey, currentPage]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await dispatch(fetchUser());
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="voilation-table">
      <div className="heading">
        <h2 className="font_xxl fw_6">Voilations</h2>
        {(role === "Property Manager" || role === "Owner") && (
          <Link to="add" className="font_m fw_6 d_block link">
            + Issue Voilations
          </Link>
        )}
      </div>

      {/* <Searchbar /> */}

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonLists}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {currentKey === GLOBAL.VIOLATION_TO.TENANT ? (
        <TenantsViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : currentKey === GLOBAL.VIOLATION_TO.TEAM ? (
        <TeamsViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <OthersViolations
          violationLists={violationLists?.result}
          totalCount={violationLists?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
/* Component Ends */
export default ClientViolationsTable;
