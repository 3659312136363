/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useNavigate, useParams } from "react-router-dom";
import GLOBAL from "../../../../setup/constants/global";
import { useSelector, useDispatch } from "react-redux";
import {
  disabledStatusSwitch,
  canCancelInvitation,
  canResendInvitation,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser } from "../../../../setup/store/slices/authSlice";
import {
  updateMemberStatus,
  updateApprovalStatus,
} from "../../../../setup/store/slices/teamSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import Table from "react-bootstrap/Table";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  DocFilledWhite,
  DeleteGrey,
  EditWhite,
  DocumentGreen,
  EditGreen,
  DeleteRed,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import StatusHighLight from "../StatusHighLight/StatusHighLight";
import TeamDetailModal from "../../../ui/modal/TeamDetailModal/TeamDetailModal";
import IconButton from "../../../ui/button/IconButton/IconButton";
import SliderButton from "../../../ui/button/SliderButton/SliderButton";
import ContractDetailModal from "../../../ui/modal/ContractDetailModal/ContractDetailModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import TPMTableUnderTPC from "../../ThirdPartyManager/TPMTableUnderTPC/TPMTableUnderTPC";
import TPTTableUnderTPC from "../../ThirdPartyTechnician/TPTTableUnderTPC/TPTTableUnderTPC";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { Accordion } from "react-bootstrap";

/* Component starts */
const TPCTable = (props) => {
  /* Props destructuring starts */
  const {
    tpc,
    userRoles,
    canInvite,
    setSelectedTeamMember,
    showWarning,
    canInviteNewMember,
    canDeactivateMember,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [detail, setDetail] = useState({});
  const [showTeamDetailModal, setShowTeamDetailModal] = useState(false);
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();

  // Redux Selector
  const user = useSelector(getUser);
  const isClient = localStorage.getItem("user_role") === "Client";
  const isFM = localStorage.getItem("user_role") === "Facility Manager";
  const isFMC =
    localStorage.getItem("user_role") === "Facility Management Company";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const showSlider = tpc?.length > 1;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Checking if the current user can approve the request
  const canUserApprove = (approvers) => {
    return approvers.some((item) => item === user.role_id);
  };

  // On view detail
  const onViewDetail = (teamMember) => {
    setDetail(teamMember);
    setShowTeamDetailModal(true);
    setShowApprove(
      teamMember?.status === GLOBAL.USER_STATUS.PENDING_APPROVAL &&
        canUserApprove(teamMember?.approver_roles)
    );
  };

  // On view contract
  const onViewContract = (teamMember) => {
    setDetail(teamMember);
    setShowContractDetailModal(true);
  };

  // On deactivate team (Company as well as Employee)
  const onDelete = async (teamMember) => {
    changeStatus(teamMember, GLOBAL.USER_STATUS.DEACTIVATED);
  };

  // On toggle activating or suspending (Company as well as Employee)
  const toggleActiveSuspend = (user, status, role) => {
    if (role === "tpt") {
      if (status) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else {
        changeStatus(user, GLOBAL.USER_STATUS.SUSPENDED);
      }
    } else {
      if (status) {
        changeStatus(user, GLOBAL.USER_STATUS.ACTIVE);
      } else {
        setModalDesc(
          "Cannot deactivate. Need to have atleast one active Member. To automatically deactivate, you need to activate a deactivated Member."
        );
        setShowErrorModal(true);
      }
    }
  };

  // On editing team (Company as well as Employee)
  const onEdit = (teamMember, role) => {};

  // Changing status
  const changeStatus = async (user, status) => {
    // Collecting or creating payload data to be sent
    const payload = {
      member_id: user?.application_id,
      status: status,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateMemberStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };

  // On rejecting or approving request
  const requestAction = async (teamMember, action) => {
    // Collecting or creating payload data to be sent
    const payload = {
      approval_id: teamMember?.request_approval_id,
      status: action,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateApprovalStatus(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowTeamDetailModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowTeamDetailModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tpc-table-module-wrapper">
      {/* Heading */}
      <div className="main-heading">
        <h3 className="fw_6 font_xl">Third Party Company</h3>
        {(isClient || isFM || isFMC) && canInviteNewMember(tpc, "tpc") && (
          <p
            onClick={() => {
              canInvite(GLOBAL.USER_ROLE.TPC, "tpc/add");
            }}
            // to="tpc/add"
            className="fw_6 font_m font_blue"
          >
            + Add TPC
          </p>
        )}
      </div>

      {/* Table */}
      <div className="wrapper ">
        {tpc?.map((item, index) => (
          <div className="company_wrapper_outer" key={index}>
            <Accordion>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  <div className="custom-table-wrapper">
                    <Table
                      className="custom-table font_s"
                      borderless
                      responsive
                    >
                      {/* Table Heading */}
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th></th>
                          <th>Status</th>
                          <th className="text-start">Actions</th>
                        </tr>
                      </thead>
                      {/* Table Body */}
                      <tbody>
                        <tr>
                          {/* Employee ID */}
                          <td>
                            <p className="col-different1">#{index + 1}</p>
                          </td>

                          {/* Company Name */}
                          <td>
                            <p className="col-different2">
                              {item?.business_name}
                            </p>
                          </td>

                          {/* Status Change */}
                          <td>
                            <div className="col-different3">
                              {showSlider && item?.status !== "expired" && (
                                <SliderButton
                                  active={
                                    item?.status === GLOBAL.USER_STATUS.ACTIVE
                                  }
                                  onChange={(state) =>
                                    (isClient || isFM) &&
                                    toggleActiveSuspend(item, state, "tpc")
                                  }
                                  disabled={disabledStatusSwitch(item?.status)}
                                />
                              )}
                            </div>
                          </td>

                          {/* Status */}
                          <td className="fw_6">
                            <p className="col-different4">
                              <StatusHighLight status={item?.status} />
                            </p>
                          </td>

                          {/* Actions */}
                          <td className="text-start">
                            {/* View Detail */}
                            <IconButton
                              className="action-btn"
                              onClick={() => onViewDetail(item)}
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Detail"
                              />
                            </IconButton>

                            {/* View Contract */}
                            <IconButton
                              className="action-btn mx-2"
                              onClick={() => onViewContract(item)}
                            >
                              <img
                                src={DocumentGreen}
                                className="h_100"
                                alt="View Contract"
                              />
                            </IconButton>

                            {/* Edit */}
                            {(isClient || isFM) &&
                              item.status ===
                                GLOBAL.USER_STATUS.PENDING_APPROVAL && (
                                <IconButton
                                  className="action-btn"
                                  onClick={() => onEdit(item, "tpc")}
                                >
                                  <img
                                    src={EditGreen}
                                    className="h_100"
                                    alt="Edit"
                                  />
                                </IconButton>
                              )}

                            {/* Delete */}
                            {/* {(isClient || isFM) &&
                              canDeactivateMember(tpc, "tpc") && (
                                <IconButton
                                  className="action-btn"
                                  onClick={() => onDelete(item)}
                                >
                                  <img
                                    src={DeleteRed}
                                    className="h_100"
                                    alt="Delete"
                                  />
                                </IconButton>
                              )} */}

                            {(isClient || isFM) &&
                              canResendInvitation(item.status) && (
                                <TextButton
                                  onClick={() => {
                                    setSelectedTeamMember(item);
                                    showWarning("resend");
                                  }}
                                  className="font_m v_middle fw_6 action-txt-btn"
                                >
                                  Resend
                                </TextButton>
                              )}

                            {(isClient || isFM) &&
                              canCancelInvitation(item.status) && (
                                <TextButton
                                  onClick={() => {
                                    setSelectedTeamMember(item);
                                    showWarning("cancel");
                                  }}
                                  className="font_m v_middle fw_6 font_red action-txt-btn"
                                >
                                  Cancel
                                </TextButton>
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="company_wrapper">
                    {item?.status === GLOBAL.USER_STATUS.DEACTIVATED && (
                      <div>
                        <p className="text-center fw_4 font_l font_yellow mt-3">
                          Third Party Company{" "}
                          <span className="text-decoration-underline fw_6">
                            {item?.business_name}
                          </span>{" "}
                          is deactivated
                        </p>
                      </div>
                    )}

                    {/* Company Table */}

                    {(item?.status === GLOBAL.USER_STATUS.ACTIVE ||
                      item?.status === GLOBAL.USER_STATUS.SUSPENDED) &&
                      item?.subMembers && <div className="bottom-arrow"></div>}

                    {/* ************************************************************************************************************************************************ */}

                    {/* Thid Party Manager Table */}
                    {(item?.status === GLOBAL.USER_STATUS.ACTIVE ||
                      item?.status === GLOBAL.USER_STATUS.SUSPENDED) && (
                      <div className="inner-wrapper inner-wrapper-1">
                        <TPMTableUnderTPC
                          tpcList={tpc}
                          subMembers={
                            item?.subMembers
                              ? item?.subMembers["Third Party Manager"]
                              : []
                          }
                          parent={item}
                          userRoles={userRoles}
                          disabledStatusSwitch={disabledStatusSwitch}
                          canResendInvitation={canResendInvitation}
                          canCancelInvitation={canCancelInvitation}
                          setSelectedTeamMember={setSelectedTeamMember}
                          toggleActiveSuspend={toggleActiveSuspend}
                          onViewDetail={onViewDetail}
                          onEdit={onEdit}
                          onDelete={onDelete}
                          canInvite={canInvite}
                          showWarning={showWarning}
                          canInviteNewMember={canInviteNewMember}
                          canDeactivateMember={canDeactivateMember}
                        />
                      </div>
                    )}

                    {/* ************************************************************************************************************************************************ */}

                    {/* Maintainance Technician Table */}
                    {(item?.status === GLOBAL.USER_STATUS.ACTIVE ||
                      item?.status === GLOBAL.USER_STATUS.SUSPENDED) &&
                      item?.subMembers &&
                      item?.subMembers["Third Party Manager"].length > 0 && (
                        <div className="inner-wrapper inner-wrapper-2">
                          <TPTTableUnderTPC
                            tpcList={tpc}
                            subMembers={
                              item?.subMembers
                                ? item?.subMembers["Third Party Technician"]
                                : []
                            }
                            parent={item}
                            userRoles={userRoles}
                            disabledStatusSwitch={disabledStatusSwitch}
                            canResendInvitation={canResendInvitation}
                            canCancelInvitation={canCancelInvitation}
                            setSelectedTeamMember={setSelectedTeamMember}
                            toggleActiveSuspend={toggleActiveSuspend}
                            onViewDetail={onViewDetail}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            canInvite={canInvite}
                            showWarning={showWarning}
                          />
                        </div>
                      )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ))}

        {/* If No TPC added yet */}
        {(!tpc || tpc?.length === 0) && (
          <h3 className="not-added-yet text-center font_m font_yellow fw_6">
            No Third Party Company added yet
          </h3>
        )}
      </div>

      {/* Modals */}
      {/* Team details Modal */}
      <TeamDetailModal
        showModal={showTeamDetailModal}
        detail={detail}
        showApprovalReq={showApprove}
        requestAction={requestAction}
        user={user}
        onClose={() => {
          setShowTeamDetailModal(false);
        }}
      />

      {/* Contract detail modal */}
      <ContractDetailModal
        user={user}
        showModal={showContractDetailModal}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Success"
        onClose={() => setShowSuccessModal(false)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Success"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default TPCTable;
