/**
 *
 */

import React, { useState, useEffect, useRef } from "react";
/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import { fieldPropExtractor, initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../../setup/store/slices/propertySlice";
import { addFoundItem } from "../../../../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../../../setup/constants/global";

import CustomTextInput from "../../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../../ui/button/TextButton/TextButton";
import CustomDateTimeInput from "../../../../../ui/input/CustomDateTimeInput/CustomDateTimeInput";
import SuccessModal from "../../../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../../ui/modal/ErrorModal/ErrorModal";

/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddFoundItem = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  let initialFormValues = initialValues;

  /* Component states and useRef declaration starts */
  const [date, setDate] = useState(new Date());
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const property = useSelector(getPropertyDetail);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  // Form Validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    // Payload To Sent For Backend
    const payload = {
      property_id: propertyId,
      itemshortdescription: values.short_desc,
      itemlongdescription: values.long_desc,
      location: values?.location_found,
      date,
      found_by: values?.found_by,
      file: [],
    };

    values.photos.forEach((i) => {
      if (i) {
        payload["file"]?.push(i);
      }
    });

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addFoundItem(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Added Found Item Successfully.");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [propertyId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-found-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Found Item</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Found Item Report */}
                <>
                  {/* Input for Item Short Description */}
                  <div className="form-group">
                    <CustomTextInput
                      name="short_desc"
                      label="Item Short Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.short_desc}
                      errors={errors.short_desc}
                      value={values.short_desc}
                    />
                  </div>

                  {/* Input for Item Long Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="long_desc"
                      label="Item Long Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.long_desc}
                      errors={errors.long_desc}
                      touched={touched.long_desc}
                      required={true}
                    />
                  </div>

                  {/* Input for Date & Time */}

                  <div className="form-group">
                    <CustomDateTimeInput
                      label="Found Date & Time"
                      value={date}
                      minDate={false}
                      customOnchange={(date) => setDate(date)}
                    />
                  </div>

                  {/* Input for Location Found */}
                  <div className="form-group">
                    <CustomTextInput
                      name="location_found"
                      label="Location Found"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.location_found}
                      errors={errors.location_found}
                      value={values.location_found}
                    />
                  </div>

                  {/* Input for Found By */}
                  <div className="form-group">
                    <CustomTextInput
                      name="found_by"
                      label="Found By"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.found_by}
                      errors={errors.found_by}
                      value={values.found_by}
                    />
                  </div>

                  {/* Input for ID Photo */}
                  <FieldArray
                    name="photos"
                    render={({ push, pop }) => (
                      <div className="form-group">
                        {/* Upload Image List */}
                        {values?.photos?.map((item, index) => (
                          <div
                            key={index}
                            className={index >= 1 ? "add-image" : ""}
                          >
                            <CustomFileInput
                              name={`photos[${index}]`}
                              label="Photo"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              value={values.photos[index]}
                              formikState={true}
                              touched={fieldPropExtractor(touched, index)}
                              validaterror={fieldPropExtractor(errors, index)}
                              required={false}
                              acceptable={true}
                              acceptType="image/*,video/*"
                            />
                          </div>
                        ))}

                        <div className="action-button text-center">
                          {values.photos[values.photos.length - 1] && (
                            <TextButton
                              variant="outline"
                              size="m"
                              className="txtBtn"
                              onClick={() => onAddPhoto(push)}
                            >
                              + Add More Images/Videos
                            </TextButton>
                          )}

                          {values.photos.length > 1 && (
                            <CustomButton
                              className="remove"
                              size="m"
                              onClick={() => onRemovePhoto(pop)}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Submit
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddFoundItem;
