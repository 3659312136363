/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../../../setup/store/slices/masterSlice";
import {
  fetchSecurityOfficerBasedOnProperty,
  getSecurityOfficersList,
} from "../../../../../../setup/store/slices/securitySlice";
import { setLoading } from "../../../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import OwnerLog from "./OwnerLog";
import TenantLog from "./TenantLog";
import SOLog from "./SOLog";
import SMLog from "./SMLog";
import PMLog from "./PMLog";
/* Import local pages and component ends */

/* Component starts */
const TimeLineListings = (props) => {
  /* Props destructuring starts */
  const { complaint, logs, property, user, isT1 } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const securityOfficersList = useSelector(getSecurityOfficersList);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadSecurityOfficersList = () => {
    dispatch(fetchSecurityOfficerBasedOnProperty(property?._id));
  };

  const handleRenderLogs = (log, index) => {
    if (log?.tenant && isT1) {
      return (
        <TenantLog
          complaint={log}
          active={index === 0}
          isLast={index === complaint?.logs?.length - 1}
        />
      );
    } else {
      switch (log?.user?.role_id?.name) {
        case GLOBAL.USER_ROLE.OWNER:
          return (
            <OwnerLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint?.logs?.length - 1}
              role={role}
              navigation={navigate}
            />
          );
        case GLOBAL.USER_ROLE.SO:
          return (
            <SOLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint?.logs?.length - 1}
              report={complaint?.report}
              user={user}
              role={role}
            />
          );
        case GLOBAL.USER_ROLE.SM:
          return (
            <SMLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint?.logs?.length - 1}
              user={user}
              role={role}
              securityOfficers={securityOfficersList}
            />
          );
        case GLOBAL.USER_ROLE.PM:
          return (
            <PMLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint?.logs?.length - 1}
              role={role}
              navigation={navigate}
            />
          );
        // default:
        //   break;
      }
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchUserRoles());
        await loadSecurityOfficersList();
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, [dispatch]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="security-report-timeline-list-wrapper">
      {/* Time Line List */}
      {/* {logs?.map((log, index) => (
        <div className="log-wrapper" key={index}>
          {handleRenderLogs(log, index)}
        </div>
      ))} */}

      {logs?.map((log, index) => (
        <div className="log-wrapper" key={index}>
          {/* {handleRenderLogs(log, index)} */}
          {log?.tenant &&
            log?.user?.role_id?.name === GLOBAL.USER_ROLE.TENANT && (
              <TenantLog
                complaint={log}
                active={index === 0}
                isLast={index === complaint.logs.length - 1}
              />
            )}

          {/* OWNER LOG */}
          {log?.user?.role_id?.name === GLOBAL.USER_ROLE.OWNER && (
            <OwnerLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint.logs.length - 1}
              role={role}
              navigation={navigate}
              complaintDetail={complaint}
              tenantId={complaint?.tenant_id?._id}
              unitId={complaint?.unit_id?._id}
            />
          )}

          {/* SO LOG */}
          {log?.user?.role_id?.name === GLOBAL.USER_ROLE.SO && (
            <SOLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint.logs.length - 1}
              report={complaint?.report}
              role={role}
              user={user}
              navigation={navigate}
            />
          )}

          {/* SM LOG */}
          {log?.user?.role_id?.name === GLOBAL.USER_ROLE.SM && (
            <SMLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint.logs.length - 1}
              user={user}
              role={role}
              securityOfficers={securityOfficersList}
            />
          )}

          {/* PM LOG */}
          {log?.user?.role_id?.name === GLOBAL.USER_ROLE.PM && (
            <PMLog
              complaint={log}
              active={index === 0}
              isLast={index === complaint.logs.length - 1}
              role={role}
              navigation={navigate}
              propert={property}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineListings;
