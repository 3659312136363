/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  customDateFormat,
  customMoment,
} from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  updateTenantInstallmentPlan,
  fetchInstallmentsByApplicationId,
} from "../../../../../setup/store/slices/tenantSlice";
import { createTransitionedInvoicesByInstalments } from "../../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  getPropertySettings,
  fetchPropertySettings,
} from "../../../../../setup/store/slices/propertySlice";
import { updateChequeDetails } from "../../../../../setup/store/slices/chequeSlice";
import { setLoading } from "../../../../../setup/store/slices/unpersistedSlice.js";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EditWhite,
  XGreen,
  XWhite,
  ChequeOutlineBlue,
  WarningRoundedBlue,
  WarningRoundedOutlineYellow,
  ExcalmatoryYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomDateInput from "../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import InstallmentPlanDetail from "./InstallmentPlanDetail";
import ChequeDetailModal from "../../../Invoicing/ChequeDetailModal/ChequeDetailModal";
import ShowChequeDetailModal from "../../../Invoicing/ShowChequeDetailModal/ShowChequeDetailModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import PopUpDatePicker from "../../../../ui/input/PopUpDatePicker/PopUpDatePicker";
/* Import local pages and component ends */

/* Component starts */
const InstallmentList = (props) => {
  /* Props destructuring starts */
  const {
    data = [],
    application_id,
    totalRent,
    setInvoicesList,
    unitId,
    totalAmt,
    setTotalAmt,
    instalmentPlan,
    instalmentVat,
    instalmentTax,
    setDataChanged,
    dataChanged,
    currency,
    setInstalments,
    canGenerateInvoices,
    isChanged,
    canAttachCheque,
    isChequeEnabled,
    property,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [thresholdAmt, setThresholdAmt] = useState(0);
  const [newInstallment, setNewInstallment] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newDueDates, setNewDueDates] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [openAddCheque, setOpenAddCheque] = useState(false);
  const [showChequeDetail, setShowChequeDetail] = useState(false);
  const [showChequeFile, setShowChequeFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const settings = useSelector(getPropertySettings);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Checking if any one installment is changed and setting dataChanged state
  const checkEqual = (installments) => {
    for (let i = 0; i < installments.length; i++) {
      if (parseFloat(installments[i]) !== parseFloat(data[i].amount)) {
        setDataChanged(true);
        return;
      }
    }
    setDataChanged(false);
  };

  // Check if the custom edited installment total amount is equal to the provided total amount
  const checkTotal = (installments) => {
    let total = installments.reduce((acc, curr) => acc + parseInt(curr), 0);

    setTotalAmt({
      errorAmt: total !== thresholdAmt,
      amount: total,
    });
  };

  // Handling on installment change event
  const handleChange = (value, index) => {
    if (isNaN(Number(value)) || value[value.length - 1] === ".") {
      return false;
    } else {
      if (value[0] === "0" && value[1] !== undefined) {
        return false;
      } else {
        const array = [...newInstallment];
        array[index] = value;
        checkEqual(array);
        checkTotal(array);
        setNewInstallment(array);
      }
    }
  };

  // Handling on due date change event
  const handleDateChange = (date, index) => {
    const array = [...newDueDates];
    array[index] = new Date(date);
    setNewDueDates(array);
    setDataChanged(true);
  };

  // Date Validations
  const dateValidations = () => {
    for (let i = 0; i < newDueDates.length; i++) {
      if (i > 0) {
        if (
          newDueDates[i].toDateString() === newDueDates[i - 1].toDateString()
        ) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                "A due date selected is same as it's previous due date. Please check again",
            })
          );
          return false;
        }
        if (!moment(newDueDates[i]).isAfter(newDueDates[i - 1])) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                "A due date selected is not after it's previous due date. Please check again",
            })
          );
          return false;
        }
      }

      if (moment(newDueDates[i]).isAfter(instalmentPlan?.end_date)) {
        dispatch(
          showErrorAlert({
            title: "Error!",
            description:
              "A due date selected is after the installment plan end date. Please check again",
          })
        );
        return false;
      }
    }
    return true;
  };

  const hasAttachedCheques = () => {
    if (instalmentPlan?.cheque && data && data?.length > 0) {
      for (let i = 0; i < data?.length; i++) {
        if (!data[i].cheque && !getIsDuePassed(data[i].due_date)) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: "Please attach cheque to all upcoming invoices.",
            })
          );
          return false;
        }
      }
    }
    return true;
  };

  const getIsDuePassed = (dueDate) => {
    return moment(dueDate).isBefore(moment());
  };

  const onGenerateInvoices = async () => {
    setInvoicesList([]);
    if (!hasAttachedCheques()) return;
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        createTransitionedInvoicesByInstalments({
          application_id,
        })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          getInstalmentsByApplicationId();
          setInvoicesList(result.payload.invoices);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setDataChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onUpdate = async () => {
    setEditIndex(null);
    if (totalAmt.errorAmt) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description:
            "Edited installment amount is not matching with the provided Total Rent.",
        })
      );
      return;
    }
    if (!dateValidations()) {
      return;
    }
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      updated_installment: [],
      property_id: propertyId,
      unit_id: unitId,
    };

    data.forEach((item, index) => {
      payload.updated_installment.push({
        _id: item?._id,
        amount: parseInt(newInstallment[index]),
        due_date: newDueDates[index],
      });
    });

    payload["updated_installment"] = JSON.stringify(
      payload["updated_installment"]
    );

    console.log("Payload", payload);
    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantInstallmentPlan(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setDataChanged(false);
          setInstalments(result.payload.installments);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const getTotal = (amt, collectVat, collectTax) => {
    let amount = amt;
    if (collectVat && settings?.collect_vat_enabled) {
      amount += (amt * settings?.collect_vat_value) / 100;
    }
    if (collectTax && settings?.collect_tax_enabled) {
      amount += (amt * settings?.collect_tax_value) / 100;
    }
    // return `${amount.toFixed(2)}`;
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const getInstalmentsByApplicationId = async () => {
    try {
      const result = await dispatch(
        fetchInstallmentsByApplicationId({ application_id })
      );
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setInstalments([]);
          break;
        case GLOBAL.FULFILLED:
          setInstalments(result.payload);
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // On updating cheque details
  const onChequeUpdate = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      installment_id: selectedInstallment?._id,
      issuer_name: values?.cheque_issuer,
      account_number: values?.account_no,
      cheque_number: values?.cheque_number,
      cheque_amount: selectedInstallment?.amount,
      payment_amount: selectedInstallment?.amount,
      cheque_date: new Date(selectedInstallment?.due_date),
      bank_name: values?.bank_name,
      cheque_doc: values?.cheque_doc,
      application_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateChequeDetails(payload));
      await getInstalmentsByApplicationId();
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  // Calculating/Adding all installments amount to get the total installment amount
  useEffect(() => {
    if (data.length > 0) {
      const installments = [];
      const dates = [];
      const totalAmountValue = parseInt(
        data.reduce((acc, curr) => acc + curr.amount, 0)
      );

      data.forEach((item) => {
        installments.push(item.amount + "");
        // dates.push(new Date(item.due_date));
        dates.push(customDateFormat(item.due_date, property?.offset));
      });

      setThresholdAmt(totalAmountValue);
      setTotalAmt((prevState) => ({
        errorAmt: parseInt(totalRent) !== parseInt(totalAmountValue),
        amount: totalAmountValue,
      }));
      setNewInstallment(installments);
      setNewDueDates(dates);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(setLoading(true));
        await dispatch(fetchPropertySettings({ property_id: propertyId }));
        await dispatch(setLoading(false));
      } catch (e) {}
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="installment-list-wrapper">
      <h3 className="font_m fw_6 title">Installment Plan Summary</h3>
      <div className="top-options">
        <InstallmentPlanDetail
          settings={settings}
          currency={currency}
          installmentPlan={instalmentPlan}
          property={property}
        />
      </div>

      <h3 className="font_m fw_6 title">Installments</h3>
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount ({currency})</th>
              <th>Due Date</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                {/* Name */}
                <td className="txt-nowrap">
                  {item?.name}
                  {(editIndex === null || editIndex !== index) && (
                    <>
                      {settings?.collect_vat_enabled && instalmentVat && (
                        <p>VAT({settings?.collect_vat_value}%)</p>
                      )}
                      {settings?.collect_tax_value && instalmentTax && (
                        <p>Tax({settings?.collect_tax_value}%)</p>
                      )}

                      {(settings?.collect_vat_enabled && instalmentVat) ||
                      (settings?.collect_tax_enabled && instalmentTax) ? (
                        <p>Total</p>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </td>
                {/* Amount */}
                <td className="amount-column-wrapper">
                  <div className="txt-nowrap amount-wrapper">
                    {editIndex === null || editIndex !== index ? (
                      <input
                        className={`amt-input transparent-input v_middle ${
                          editIndex === null || editIndex !== index
                            ? ""
                            : "edit-input"
                        }`}
                        type="text"
                        onChange={({ target: { value } }) =>
                          handleChange(value, index)
                        }
                        value={`${parseInt(
                          newInstallment[index]
                        )?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`}
                        readOnly={editIndex === null || editIndex !== index}
                      />
                    ) : (
                      <CustomCurrencyInput
                        value={`${newInstallment[index]}`}
                        onCustomChange={(value) => handleChange(value, index)}
                        label={`${item?.name} Amount`}
                        currency={currency}
                      />
                    )}

                    {(editIndex === null || editIndex !== index) && (
                      <>
                        {settings?.collect_vat_enabled && instalmentVat && (
                          <p>
                            {newInstallment[index]
                              ? (
                                  (parseFloat(newInstallment[index]) *
                                    settings?.collect_vat_value) /
                                  100
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </p>
                        )}
                        {settings?.collect_tax_enabled && instalmentTax && (
                          <p>
                            {newInstallment[index]
                              ? (
                                  (parseFloat(newInstallment[index]) *
                                    settings?.collect_tax_value) /
                                  100
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </p>
                        )}
                        {(settings?.collect_vat_enabled && instalmentVat) ||
                        (settings?.collect_tax_enabled && instalmentTax) ? (
                          <p>
                            {getTotal(
                              parseFloat(newInstallment[index]),
                              instalmentVat,
                              instalmentTax
                            )}
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </td>
                {/* Due Date */}
                <td className="date-column-wrapper">
                  <div className="datepick-wrapper">
                    {editIndex === null || editIndex !== index ? (
                      moment(newDueDates[index]).format(DATE_FORMAT)
                    ) : (
                      <PopUpDatePicker
                        label={`${item?.name} Due Date`}
                        title={`${item?.name} Due Date`}
                        date={newDueDates[index]}
                        handleChange={(date) => handleDateChange(date, index)}
                        maxDate={new Date(instalmentPlan?.end_date)}
                        minDate={
                          index === 0
                            ? new Date(instalmentPlan.start_date)
                            : new Date(
                                moment(newDueDates[index - 1]).add(1, "d")
                              )
                        }
                      />
                    )}
                  </div>
                </td>
                {/* Action */}
                <td className="action-column">
                  {editIndex !== null && editIndex === index ? (
                    <TextButton
                      onClick={() => setEditIndex(null)}
                      className="action-btn font_m font_blue fw_6"
                    >
                      Save
                      {/* <img className="h_100" src={XGreen} alt="Cancel" /> */}
                    </TextButton>
                  ) : (
                    <IconButton
                      onClick={() => setEditIndex(index)}
                      className="action-btn"
                    >
                      <img className="h_100" src={EditGreen} alt="Edit" />
                    </IconButton>
                  )}

                  {isChequeEnabled && canAttachCheque && (
                    <>
                      {item?.cheque ? (
                        <>
                          <IconButton
                            onClick={() => {
                              setSelectedInstallment(item);
                              setShowChequeDetail(true);
                            }}
                            className="cheque-flow-btn"
                          >
                            <img
                              src={ChequeOutlineBlue}
                              className="icon-lg"
                              alt="View Cheque"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setSelectedInstallment(item);
                              setOpenAddCheque(true);
                            }}
                            className="cheque-flow-btn"
                          >
                            <img
                              src={WarningRoundedBlue}
                              className="icon-lg"
                              alt="Replace Cheque"
                            />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setSelectedInstallment(item);
                            setOpenAddCheque(true);
                          }}
                          className="cheque-flow-btn"
                        >
                          <img
                            src={WarningRoundedOutlineYellow}
                            className="icon-lg"
                            alt="Add Cheque"
                          />
                        </IconButton>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td className="last-row-cells">
                <p
                  className={`fw_6 font_xl ${
                    totalAmt.errorAmt ? "font_red" : "font_blue"
                  }`}
                >
                  Total
                </p>
              </td>
              <td className="last-row-cells">
                <p
                  className={`fw_6 font_xl ${
                    totalAmt.errorAmt ? "font_red" : "font_blue"
                  }`}
                >
                  ${totalAmt.amount}
                </p>
              </td>
              <td className="last-row-cells">
                {totalAmt.errorAmt && (
                  <p className="font_red font_xs fw_5">
                    Installment total amount not matching with the provided
                    Total Rent Amount
                  </p>
                )}
              </td>
              <td className="last-row-cells"></td>
            </tr>
          </tbody>
        </Table>

        {/*  */}
        {/* {dataChanged ? (
          <CustomButton size="m" className="btn_mid" onClick={onUpdate}>
            Update Installmemnt
          </CustomButton>
        ) : (
          <CustomButton size="m" className="btn_mid" onClick={onSend}>
            Generate installment
          </CustomButton>
        )} */}
        {canGenerateInvoices ? (
          <CustomButton
            size="m"
            className="btn_mid"
            onClick={onGenerateInvoices}
          >
            Generate Invoices
          </CustomButton>
        ) : (
          <>
            {!isChanged && dataChanged && (
              <CustomButton size="m" className="btn_mid" onClick={onUpdate}>
                Update Installment
              </CustomButton>
            )}
          </>
        )}
      </div>

      <ChequeDetailModal
        amt={selectedInstallment?.amount}
        date={selectedInstallment?.due_date}
        showModal={openAddCheque}
        onClose={() => setOpenAddCheque(false)}
        onSubmit={onChequeUpdate}
      />

      <ShowChequeDetailModal
        data={selectedInstallment?.cheque || selectedInstallment}
        isCheque={selectedInstallment?.cheque && true}
        showModal={showChequeDetail}
        onClose={() => setShowChequeDetail(false)}
        setShowFile={setShowChequeFile}
      />

      <FileViewerModal
        show={showChequeFile}
        file={selectedInstallment?.cheque?.document?.url}
        type={selectedInstallment?.cheque?.document?.type}
        onClose={() => setShowChequeFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default InstallmentList;
