/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
import {
  fetchTeamMembers,
  getTeamMembers,
  fetchCanInviteTeam,
  resendInvitationLink,
  cancelInvitationLink,
  getMissingMember,
  getIsTeamMissing,
} from "../../setup/store/slices/teamSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
  fetchPropertySettings,
  getPropertySettings,
} from "../../setup/store/slices/propertySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */

/* Import image and SVG ends */

/* Import local pages and component starts */
import PMCTable from "../../components/module/PropertyManagementCompany/PMCTable/PMCTable";
import FMCTable from "../../components/module/FacilityManagementCompany/FMCTable/FMCTable";
import SMCTable from "../../components/module/SecurityManagementCompany/SMCTable/SMCTable";
import TPCTable from "../../components/module/ThirdPartyCompany/TPCTable/TPCTable";
import OwnerTable from "../../components/module/Owner/OwnerTable/OwnerTable";
import ConfirmationModal from "../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import Missing from "./local-components/Missing";
import MissingTeamDetail from "./local-components/MissingTeamDetail";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ManageTeams = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [invitationAction, setInvitationAction] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState({
    title: "",
    description: "",
  });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const navigate = useNavigate();
  // Redux Selector
  const userRoles = useSelector(getUserRoles);
  const teamMembers = useSelector(getTeamMembers);
  const property = useSelector(getPropertyDetail);
  const settings = useSelector(getPropertySettings);
  const missingMember = useSelector(getMissingMember);
  const isTeamMissing = useSelector(getIsTeamMissing);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const canInvite = async (role_name, navigateTo) => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId, role_name };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchCanInviteTeam(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description: result?.payload?.message || "",
          //   })
          // );
          navigate(navigateTo);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const showWarning = (type) => {
    setInvitationAction(type);
    setShowConfirmation(true);
    switch (type) {
      case "resend":
        setConfirmationMessage({
          title: "Resend Invitation",
          description: "Are you sure you want to resend invitation ?",
        });
        break;
      // --------------------
      case "cancel":
        setConfirmationMessage({
          title: "Cancel Invitation",
          description: "Are you sure you want to cancel invitation ?",
        });
        break;
    }
  };

  const handleInvitationAction = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      invitation_id: selectedTeamMember.invitation_id?._id,
      property_id: propertyId,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (invitationAction === "resend") {
        console.log("Invitation has been resend");
        result = await dispatch(resendInvitationLink(payload));
      } else if (invitationAction === "cancel") {
        console.log("Invitation has been cancelled");
        result = await dispatch(cancelInvitationLink(payload));
      }

      console.log("Response", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
      setShowConfirmation(false);
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const showAddMemberButton = (role, data) => {
    if (role === "acc") {
      if (data) {
        const fm =
          data?.subMembers &&
          data?.subMembers["Facility Manager"] &&
          data?.subMembers["Facility Manager"]?.length > 0
            ? data?.subMembers["Facility Manager"]
            : [];
        const mt =
          data?.subMembers &&
          data?.subMembers["Maintenance Technician"] &&
          data?.subMembers["Maintenance Technician"]?.length > 0
            ? data?.subMembers["Maintenance Technician"]
            : [];

        if (fm?.length === 0 || mt?.length === 0) return false;

        let fmAdded = false;
        let mtAdded = false;

        for (let i = 0; i < fm.length; i++) {
          if (fm[i]?.status === "active") {
            fmAdded = true;
            break;
          }
        }

        for (let i = 0; i < mt.length; i++) {
          if (mt[i]?.status === "active") {
            mtAdded = true;
            break;
          }
        }

        return fmAdded && mtAdded;
      }
      return false;
    }
    if (role === "fmc") {
    }
    if (role === "fm") {
    }
    if (role === "tpc") {
    }
    if (role === "tpm") {
    }
    return true;
  };

  const canDeactivateMember = (data, role) => {
    if (
      (role === "fmc" || role === "fm" || role === "tpc" || role === "tpm") &&
      data &&
      data?.length > 0
    ) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.status === GLOBAL.USER_STATUS.SUSPENDED) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  };

  const canInviteNewMember = (data, role) => {
    if (
      (role === "fmc" || role === "fm" || role === "tpc" || role === "tpm") &&
      data &&
      data?.length > 0
    ) {
      for (let i = 0; i < data.length; i++) {
        if (
          data[i]?.status === GLOBAL.USER_STATUS.SUSPENDED ||
          data[i]?.status === GLOBAL.USER_STATUS.PENDING_INVITATION
        ) {
          return false;
        }
      }
      return true;
    } else {
      return true;
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const minimumActiveMemberWarning = () => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching User Roles and Team Members on initial load
  useEffect(() => {
    (async () => {
      await dispatch(setLoading(true));
      await dispatch(fetchUserRoles());
      await dispatch(fetchTeamMembers({ property_id: propertyId }));
      await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
      await dispatch(fetchPropertySettings({ property_id: propertyId }));
      await dispatch(setLoading(false));
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="manage_teams" />

      <div className="manage-teams-page-wrapper container_lg">
        <h1 className="page-heading font_xxl fw_6">Teams</h1>

        {teamMembers && (
          <>
            {isTeamMissing && missingMember?.length > 0 && (
              // <Missing
              //   mgmt={property?.mgmt_model}
              //   isT1={property?.mgmt_model === 0}
              //   isT2={property?.mgmt_model === 1}
              //   isT3={property?.mgmt_model === 2}
              //   members={teamMembers}
              //   missingMember={missingMember}
              // />
              <MissingTeamDetail missingMemberDetails={missingMember} />
            )}

            {/* Owner Table */}
            <OwnerTable
              owners={teamMembers["Owner"]}
              canInvite={canInvite}
              setSelectedTeamMember={setSelectedTeamMember}
              showWarning={showWarning}
              isT2={property?.mgmt_model === 1}
              userRoles={userRoles}
            />

            {/* Property Management Company */}
            {property?.mgmt_model === 2 && (
              <PMCTable
                pmc={teamMembers["Property Management Company"]}
                userRoles={userRoles}
                canInvite={canInvite}
                setSelectedTeamMember={setSelectedTeamMember}
                showWarning={showWarning}
              />
            )}

            {/* Facility Management Company */}
            {/*     {((property?.mgmt_model === 2 &&
            teamMembers["Property Management Company"]?.length) ||
            property?.mgmt_model === 1) && (
            <FMCTable
              fmc={teamMembers["Facility Management Company"]}
              userRoles={userRoles}
            />
          )} */}

            {(property?.mgmt_model === 2 || property?.mgmt_model === 1) && (
              <FMCTable
                fmc={teamMembers["Facility Management Company"]}
                userRoles={userRoles}
                canInvite={canInvite}
                setSelectedTeamMember={setSelectedTeamMember}
                showWarning={showWarning}
                showAddMemberButton={showAddMemberButton}
                canInviteNewMember={canInviteNewMember}
                canDeactivateMember={canDeactivateMember}
              />
            )}

            {/* Security Management Company */}
            {
              // teamMembers["Facility Management Company"]?.length > 0 &&
              settings?.can_tenant_complain_security_request &&
                property?.mgmt_model === 2 && (
                  <SMCTable
                    smc={teamMembers["Security Management Company"]}
                    userRoles={userRoles}
                    canInvite={canInvite}
                    setSelectedTeamMember={setSelectedTeamMember}
                    showWarning={showWarning}
                  />
                )
            }

            {/* Third Party Company */}
            {
              // teamMembers["Facility Management Company"]?.length > 0 &&
              (property?.mgmt_model === 2 || property?.mgmt_model === 1) && (
                <TPCTable
                  tpc={teamMembers["Third-Party Company"]}
                  userRoles={userRoles}
                  canInvite={canInvite}
                  setSelectedTeamMember={setSelectedTeamMember}
                  showWarning={showWarning}
                  canInviteNewMember={canInviteNewMember}
                  canDeactivateMember={canDeactivateMember}
                />
              )
            }

            <ConfirmationModal
              showModal={showConfirmation}
              onConfirm={handleInvitationAction}
              title={confirmationMessage.title}
              description={confirmationMessage.description}
              onClose={() => setShowConfirmation(false)}
            />
          </>
        )}
      </div>
    </>
  );
};
/* Component ends */

export default ManageTeams;
